import React from "react";

const NavLinks = ({ svg, link, text, onClick }) => {
  return (
    <div
      style={{
        textDecoration: "none",
        cursor: "pointer",
        
      }}
      onClick={onClick}
    >
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default NavLinks;
