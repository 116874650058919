import React from "react";
import styles from "./Dummy.module.css";
const Dummy = () => {
  return (
    <div
      class="botMessageWrapper w-100"
      className={`${styles.card} botMessageWrapper w-100`}
    >
      <div class="openaiSVG">
        <img
          src="/64x64.svg"
          alt="verifiedX"
          style={{ height: "80px", width: "80px", borderRadius: "50%" }}
        ></img>
      </div>
      <div className="d-flex flex-column justify-content-center">
        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "74px",
            height: "13px",
            backgroundColor: "rgb(187, 255, 75)",
            borderRadius: "4px",
          }}
        >
          <br></br>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "95%",
            height: "13px",
            backgroundColor: "rgb(46 52 37)",
            borderRadius: "4px",
            marginTop: "5px",
          }}
        >
          <br></br>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "95%",
            height: "13px",
            backgroundColor: "rgb(46 52 37)",
            borderRadius: "4px",
            marginTop: "5px",
          }}
        >
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Dummy;
