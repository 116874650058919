import React, { createContext, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/Logo/Logo_hor_green.svg";
import AVATAR from "../../assets/Favicon/avatar.svg";
import HistorySideNav from "../../components/HistorySideNav";
import Avatar from "../../components/Avatar";
import { Col, Row } from "react-bootstrap";
const Box = ({ chatLog, refs }) => {
  function splitMessage(message) {
    const components = message.split("-");
    const first = components[0];
    const rest = components.slice(1).join("-");
    return [first, rest];
  }
  function getMessageColor(message) {
    if (message.includes("No Scam")) {
      return "#BBFF4B";
    }

    const riskLevelRegex = /Risk Level: (\d+)%/;
    const match = message.match(riskLevelRegex);
    if (match) {
      const riskLevel = parseInt(match[1], 10);
      if (riskLevel <= 60) {
        return "green";
      }
    }

    return "red";
  }

  return (
    <section className="chatBox">
      {
        <div className="chatLogWrapper" style={{ height: "100vh" }}>
          {chatLog.map((chat, idx) => {
            console.log("chat message :", chat);
            const [firstSentence, restOfMessage] = splitMessage(chat.message);
            return (
              <div
                className="chatLog"
                style={{ width: "100%" }}
                key={idx}
                id={`chat-${idx}`}
                ref={(el) => (refs.current[idx] = el)}
              >
                <div className="chatPromptMainContainer">
                  {/* <div className="chatPromptWrapper" > */}
                  <Row
                    style={{
                      maxWidth: "60%",
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginBottom: "15px",
                    }}
                  >
                    <Col xs={"auto"} style={{ width: "80px" }}>
                      <div className="userSVG">
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            border: "3px solid #bbff4b",
                          }}
                          src={AVATAR}
                          alt="User Avatar"
                          className="avatar"
                        />
                      </div>
                    </Col>
                    <Col
                      className="d-flex flex-column justify-content-center"
                      style={{
                        border: "4px solid #bbff4b",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Cont-regular",
                          overflowWrap: "break-word",
                          display: "inline-block",
                          textAlign: "end",
                          padding: "5px 0",
                        }}
                        id="chatPrompt"
                      >
                        {chat.originalUrl}
                      </div>
                    </Col>
                  </Row>
                  {/* </div> */}
                </div>

                <div
                  className="botMessageMainContainer"
                  // style={{
                  //   // @ts-ignore
                  //   Color: chat.phishing ? "red" : "green",
                  // }}
                >
                  {/* <div className="botMessageWrapper"> */}
                  <Row className="w-100" style={{ marginBottom: "15px" }}>
                    <Col xs={"auto"} style={{}}>
                      <Avatar className="openaiSVG" bg={undefined}>
                        <img
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "50%",
                          }}
                          src="/64x64.svg"
                          alt="verifiedX"
                        />
                      </Avatar>
                    </Col>

                    <Col className="d-flex flex-column p-0 ">
                      <div
                        style={{
                          width: "70%",
                          textAlign: "left",
                          display: "inline-block",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Cont-Regular",
                            fontSize: 16,
                            color: getMessageColor(firstSentence),
                          }}
                        >
                          {firstSentence}
                        </span>
                        {restOfMessage.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              // </div>
            );
          })}
        </div>
      }
    </section>
  );
};
const UrlHistory = () => {
  const location = useLocation();
  const { urls } = location.state || { urls: [] };
  const refs = useRef([]);
  const handleScroll = (idx) => {
    const scrollIntoView = (ind) => {
      const element = refs.current[ind];
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    };
    scrollIntoView(idx);
  };
  return (
    <>
      <HistorySideNav urls={urls} scrollTo={handleScroll} />
      <Box chatLog={urls} refs={refs}></Box>
    </>
  );
};

export default UrlHistory;
