import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../api.config";
// import { listTeamMembers } from "../utils/api";
import { useData } from "../utils/DataContext";

const NewChat = ({ setChatLog, setShowMenu }) => {
  const [filteredMembers, setFilteredMembers] = useState([]);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isTeam, setIsTeam] = useState(false);

  const memberUser = localStorage.getItem("user_id");
  const role = localStorage.getItem("role");
  const plan = localStorage.getItem("plan");
  const userId = localStorage.getItem("user_id");
  const { listMembers } = useData();

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        console.log(" api call from NavContent", listMembers);

        let members = (await listMembers) ?? [];
        console.log(members.data.teamMembers);
        // members = members.data.teamMembers.filter(
        //   (member) => member._id !== userId
        // );
        console.log(
          members.data.teamMembers.filter((item) => item._id !== userId)
        );
        if (role === "member" && plan === "team") {
          setFilteredMembers([
            ...members.data.teamMembers?.filter((item) => item._id !== userId),
            members.data.admin,
          ]);
        } else {
          setFilteredMembers([...members.data.teamMembers]);
        }
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };
    fetchTeamMembers();
  }, [listMembers]);
  const handleSelect = async (eventKey) => {
    const selectedMember = filteredMembers.find(
      // @ts-ignore
      (member) => member._id === eventKey
    );

    if (selectedMember) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/team/team-urls?shareId=${memberUser}`,
          // @ts-ignore
          { userId: selectedMember._id, isAdmin: role },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            params: { organizationId: localStorage.getItem("organization_id") },
          }
        );

        if (response.status === 200) {
          console.log("User ID sent to the API successfully");
          console.log("Received URLs:", response.data.urls);
          // @ts-ignore
          navigate(`/url-history/${selectedMember._id}`, {
            state: { urls: response.data.urls },
          });
          setIsTeam(true);
        } else {
          console.error("Failed to send user ID to the API");
        }
      } catch (error) {
        console.error("Error sending user ID to the API", error);
      }
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown sideMenuButton"
        onSelect={handleSelect}
        style={{ border: 0 }}
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          style={{
            backgroundColor: "rgb(187, 255, 75)",
            color: "black",
            width: "90%",
          }}
        >
          Shared Chats
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {filteredMembers.length > 0 ? (
            filteredMembers.map((member) => (
              // @ts-ignore
              <Dropdown.Item key={member._id} eventKey={member._id}>
                {
                  // @ts-ignore
                  member.FullName || member.email
                }
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No member available</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NewChat;
