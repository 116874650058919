import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from "../../api.config";



const ResetPasswordPage = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { token } = useParams();
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(
                `${API_BASE_URL}/auth/reset-password`,
                { token, newPassword },
                { headers: { "Content-Type": "application/json" } }
            );

            if (response.status === 200) {
                toast.success("Password has been reset successfully");
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        } catch (error) {
            toast.error("Error resetting password. Please try again.");
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div
                className="card p-4 shadow-lg"
                style={{ maxWidth: "400px", width: "100%", backgroundColor: "#282c34" }}
            >
                <div className="card-body">
                    <h1 className="card-title text-center mb-3">Reset Password</h1>
                    <form onSubmit={handleResetPassword}>
                        <div className="mb-3">
                            <input
                                type="password"
                                name="newPassword"
                                id="newPassword"
                                className="form-control"
                                placeholder="Enter new password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                className="form-control"
                                placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>

                        

                        <button
                            style={{
                                color: "#333"
                            }}
                            type="submit" className="login-button">
                            Reset Password
                        </button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ResetPasswordPage;
