import React from "react";
import BotLogo from "../assets/Favicon/128x128.svg";
import Logo from "../assets/Logo/Logo_hor_green.svg";

const IntroSection = () => {
  return (
    <>
      <div className="introsection-header-main">
        <img className="introsection-header" src={Logo} alt="Logo" />
      </div>
      <div id="introsection">
        {/* <p>Click logo to activate/deactivate screen icon</p> */}
        <img
          style={{
            borderRadius: "20%",
          }}
          src={BotLogo}
          alt="VerifiedLogo"
        />
        <h1>How can I protect you today?</h1>
      </div>
    </>
  );
};

export default IntroSection;
