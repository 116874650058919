import React, { forwardRef, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Dropdown,
  Button,
} from "react-bootstrap";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  Label,
  ResponsiveContainer,
} from "recharts";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
import { MonthCalendar } from "@mui/x-date-pickers/MonthCalendar";
import axios from "axios";
import { API_BASE_URL } from "../../api.config";
import { useNavigate } from "react-router-dom";
import { color } from "chart.js/helpers";
import Cost_Savings_and_ROI_Correlation from "./Charts/Cost_Savings_and_ROI_Correlation";
import Number_of_Phishing_Attempts_Blocked from "./Charts/Number_of_Phishing_Attempts_Blocked";
import Tested_Messages_Links_and_Detected_Scams_per_Team_Member from "./Charts/Tested_Messages_Links_and_Detected_Scams_per_Team_Member";
import Cumulative_Cost_Savings_Over_Time from "./Charts/Cumulative_Cost_Savings_Over_Time";
import dayjs, { Dayjs } from "dayjs";
import Loading from "../../components/Loading";
import Total_Messages_and_Links_Tested from "./Charts/Total_Messages_and_Links_Tested";
import { height } from "@mui/system";

class LocalizedAdapterDayjs extends AdapterDayjs {
  getYearRange = (start, end) => {
    const startDate = this.startOfYear(dayjs(start[0]));
    const endDate = this.endOfYear(dayjs(start[1]));
    let years = [];

    let currentYear = endDate;
    while (this.isBefore(startDate, currentYear)) {
      years.push(currentYear);
      currentYear = this.addYears(currentYear, -1);
    }
    return years;
  };
}
const Analytics = forwardRef((props, ref) => {
  const [savedUrls, setsavedUrls] = useState(0);
  const [totalPhishing, setTotalPhishing] = useState(0);
  const [dailyRequests, setDailyRequests] = useState([]);
  const [weeklyActivity, setWeeklyActivity] = useState([]);
  const [teamVsSingleUserUsage, setTeamVsSingleUserUsage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interval, setInterval] = useState("weekly"); // State to track selected interval
  const [isYearlyDisplay, setIsYearlyDisplay] = useState(false); //to open select year dialog box

  const [year, setYear] = useState(dayjs(new Date())); //to open select year dialog box
  useEffect(() => {
    setLoading(true);
    const fetchAnalyticsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const organizationId = localStorage.getItem("organization_id");

        const urlsResponse = await axios.post(
          `${API_BASE_URL}/analytics/total-urls`,
          { organizationId },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setsavedUrls(urlsResponse.data.savedUrls);
        setTotalPhishing(urlsResponse.data.phishingUrls);

        const dailyRequestsResponse = await axios.get(
          `${API_BASE_URL}/analytics/daily-requests`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDailyRequests(dailyRequestsResponse.data);

        fetchWeeklyActivity(interval, token);

        const teamVsSingleUserUsageResponse = await axios.get(
          `${API_BASE_URL}/analytics/team-vs-single-user-usage`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTeamVsSingleUserUsage(teamVsSingleUserUsageResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        // @ts-ignore
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, [interval, year]); // Add interval as a dependency

  const fetchWeeklyActivity = async (interval, token) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/analytics/weekly-activity?interval=${interval}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setWeeklyActivity(response.data);
    } catch (error) {
      console.error("Error fetching weekly activity data:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh", width: "100%" }}
      >
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Container fluid className="py-5 px-4">
        <Row>
          <Col>
            <h3 className="text-center" style={{ color: "red" }}>
              {error}
            </h3>
          </Col>
        </Row>
      </Container>
    );
  }

  console.log(teamVsSingleUserUsage, "teamVsSingleUserUsage");

  const userUrlCounts = teamVsSingleUserUsage.reduce((acc, entry) => {
    // @ts-ignore
    acc[entry.userName] = (acc[entry.userName] || 0) + 1;
    return acc;
  }, {});

  // Transform the data into an array suitable for the graph
  const graphData = Object.keys(userUrlCounts).map((user) => ({
    user,
    urlCount: (userUrlCounts[user] * 50000) / 1000000,
  }));

  const setYearFunc = (e) => {
    setYear(dayjs(new Date(e.$d)));
  };

  return (
    <Container
      fluid
      className="py-5 px-4"
      style={{ backgroundColor: "#000000", overflowY: "auto" }}
    >
      <button
        onClick={handleBackClick}
        style={{
          position: "absolute",
          left: "1rem",
          top: "1rem",
          backgroundColor: "#bbff4b",
          color: "#000",
          border: "none",
          padding: "0.5rem 1rem",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        Back
      </button>

      <div
        style={{
          position: "absolute",
          right: "1rem",
          top: " 1rem",
        }}
      >
        <Button
          className="position-absolute end-0  btn btn-success"
          style={{
            backgroundColor: "rgb(187, 255, 75)",
            color: "black",
          }}
          onClick={() => {
            setIsYearlyDisplay(!isYearlyDisplay);
          }}
        >
          Year
        </Button>
        {isYearlyDisplay && (
          <div
            className="position-absolute bg-white text-black"
            style={{
              right: 0,
              top: "40px",
              zIndex: 1,
              border: "1px solid #cecece",
              borderRadius: "10px",
              background: "#f8f8f8",
              boxShadow: "0px 1px 5px 0px",
            }}
          >
            <LocalizationProvider dateAdapter={LocalizedAdapterDayjs}>
              <DemoContainer
                components={["YearCalendar"]}
                sx={{ padding: "10px" }}
              >
                <DemoItem label="Select Year:">
                  <YearCalendar
                    maxDate={dayjs(new Date())}
                    minDate={dayjs(new Date()).subtract(3, "years")}
                    onChange={(e) => setYearFunc(e)}
                    defaultValue={year}
                    value={year}
                    slotProps={{
                      yearButton: {
                        sx: {
                          "&.Mui-selected": {
                            backgroundColor: "#66bb6a",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#bbff4b",
                            color: "black",
                          },
                        },
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
      </div>

      <Row className="mb-4">
        <Col>
          <h2
            style={{ fontFamily: "Cont-regular", color: "#bbff4b" }}
            className="text-center"
          >
            Analytics Dashboard
          </h2>
        </Col>
      </Row>

      <Row className="mb-4" style={{ height: "70vh" }}>
        {/* Pie Chart: Total Messages and Links Tested */}

        <Col md={6} xs={12}>
          <Card className="h-100">
            <Card.Body>
              <h4
                style={{ fontFamily: "Cont-regular", color: "black" }}
                className="mb-4"
              >
                Total Messages and Links Tested
              </h4>
              <Total_Messages_and_Links_Tested year={year.year()} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xs={12}>
          <Card className="h-100">
            <Card.Body>
              <h4
                style={{ fontFamily: "Cont-regular", color: "black" }}
                className="mb-4"
              >
                Cost Saving and Links Tested
              </h4>
              <Cost_Savings_and_ROI_Correlation year={year.year()} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4 " style={{ height: "70vh" }}>
        <Col md={6} xs={12}>
          <Card className="h-100">
            <Card.Body>
              <h4
                style={{ fontFamily: "Cont-regular", color: "black" }}
                className="mb-4"
              >
                Number of Phishing Attempts Blocked
              </h4>

              {/* <BarChart width={500} height={300} data={weeklyActivity}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart> */}
              <Number_of_Phishing_Attempts_Blocked year={year.year()} />
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} xs={12}>
          <Card className="h-100">
            <Card.Body>
              <Dropdown
                onSelect={handleIntervalChange}
                className="float-end position-absolute"
                style={{
                  right: "40px",
                  top: "60px",
                  zIndex: "1",
                }}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "rgb(187, 255, 75)",
                    color: "black",
                    right: "5px",
                    top: "10px",
                    padding: "0px 5px",
                  }}
                >
                  {interval.charAt(0).toUpperCase() + interval.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="weekly"> Weekly</Dropdown.Item>
                  <Dropdown.Item eventKey="monthly">Monthly</Dropdown.Item>
                  <Dropdown.Item eventKey="quarterly">Quarterly</Dropdown.Item>
                  <Dropdown.Item eventKey="annually">Annually</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <h4
                style={{ fontFamily: "Cont-regular", color: "black" }}
                className="mb-4"
              >
                Tested Messages Links and Detected Scams per Team Member
              </h4>

              <Tested_Messages_Links_and_Detected_Scams_per_Team_Member
                interval={interval}
                year={year.year()}
              />
              {/* <BarChart width={500} height={300} data={graphData}>
              <XAxis dataKey="user" />
              <YAxis>
                <Label
                  value="Million USD"
                  position="insideLeft"
                  angle={-90}
                ></Label>
              </YAxis>
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="urlCount" stroke="#8884d8" />
              <Bar dataKey="urlCount" fill="#8884d8" name={"URL Counts"}>
                {graphData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#8884d8" />
                ))}
              </Bar>
            </BarChart> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row
        className="mb-4 flex flex-auto justify-content-center"
        style={{ height: "70vh" }}
      >
        <Col md={6} xs={12} className="">
          <Card className="h-100">
            <Card.Body className="">
              <h4
                style={{ fontFamily: "Cont-regular", color: "black" }}
                className="mb-4"
              >
                Cumulative Cost Savings Over Time
              </h4>
              <Cumulative_Cost_Savings_Over_Time year={year.year()} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default Analytics;
