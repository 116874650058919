import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/Logo/Logo_hor_green.svg";
import MobileAppsButtons from "../../components/MobileAppsButtons";

const Login = () => {
  const hasWindow = typeof window !== "undefined";
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth);
    }
  }, [hasWindow]);

  const navigate = useNavigate();

  const handleClick = async (purpose) => {
    if (purpose === "upgrade-plan") {
      navigate("/upgrade-plan");
    }
    if (purpose === "login") {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="loginContainer">
        <div className="loginContainerContent">
          <div
            style={{
              marginBottom: "20px",
              height: "100px",
            }}
          >
            <img src={LOGO} alt="" />
          </div>
          <h1>Welcome to VerifiedX</h1>
          <p
            style={{
              marginTop: "1rem",
            }}
          >
            Your Ultimate Scam Protection Assistant
          </p>
          <div className="loginButtonWrapper">
            <Button text="Log in" handleClick={() => handleClick("login")} />
            <Button
              text="Sign up"
              handleClick={() => handleClick("upgrade-plan")}
            />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Login;
