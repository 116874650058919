import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../api.config";
import { toast } from "react-toastify"; // Import toast
import PatterImage from "../../assets/Patterns/Pattern Full 1.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

const SelectOrganization = () => {
  const navigate = useNavigate();
  const [organizationIds, setOrganizationIds] = useState([]);
  const [entities, setEntities] = useState([]);
  const [individualAccount, setIndividualAccount] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  useEffect(() => {
    const fetchOrganizations = async (arr) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/organizations/get-organizations`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              organizationIds: arr, // The array of organization IDs you want to fetch
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch organizations");
        }

        let data = await response.json();
        console.log(data);
        setEntities(data.organizations);
      } catch (error) {
        console.error("Error fetching organizations:", error);
        toast.error(error);
      }
    };
    const getUserDetail = async () => {
      const user = await axios.get(`${API_BASE_URL}/user`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      localStorage.setItem("plan", user.data.plan);
      localStorage.setItem("role", user.data.role);
      if (user.data.plan === "plus") {
        setIndividualAccount(true);
      }

      setOrganizationIds(user.data.organizationId);
      console.log(organizationIds);
      fetchOrganizations(user.data.organizationId);
    };
    getUserDetail();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await fetchOrganizations();
  //   };
  //   fetchData();
  // }, [organizationIds]);

  const handleLogin = () => {
    if (selectedOrganization === null) {
      return;
    }
    if (selectedOrganization !== "") {
      //check the organization for an adminUser if adminUser===localstorage.userId
      if (selectedOrganization.adminUser === localStorage.getItem("user_id")) {
        //for admin
        localStorage.setItem("role", "admin");
        localStorage.setItem("plan", "team");
        localStorage.setItem("organization_id", selectedOrganization._id);

      } else {
        //for team member
        localStorage.setItem("role", "member");
        localStorage.setItem("plan", "team");
        localStorage.setItem("organization_id", selectedOrganization._id);

      }
    } else {
      //for individual
      localStorage.setItem("role", "member");
      localStorage.setItem("plan", "plus");
      localStorage.setItem("organization_id", "");

    }
    navigate("/dashboard", { state: { state: "clear" } });
  };

  const handleSettingOrganizatoin = (organizationId) => {
    setSelectedOrganization(organizationId);
  };

  // console.log('org Ids: ', organizationIds)
  // console.log('organizations: ', organizations)

  return (
    <div style={{ width: "100%", overflowY: "auto" }}>
      <div
        style={{
          //   width: '100%',
          borderBottom: "1px solid gray",
          display: "flex",
          justifyContent: "center",
          padding: "10px 0px",
        }}
      >
        <div style={{ color: "#c2c2c2", fontSize: 24, fontWeight: "bold" }}>
          VerifiedX
        </div>
      </div>

      {/* organizations  */}

      {entities.length > 0 ? (
        <div
          style={{
            marginTop: "1rem",
            //   backgroundColor: 'darkseagreen',
            display: "flex",
            justifyContent: "center",
            gap: "3rem",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "57rem",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "3rem 0",
          }}
        >
          <h1>Select which account to use:</h1>

          <div
            style={{
              display: "flex",
              gap: ".7rem",
              flexWrap: "wrap",
            }}
          >
            {individualAccount && (
              <div
                // @ts-ignore
                // key={organization._id}
                onClick={() => {
                  handleSettingOrganizatoin("");
                }}
                className="card"
                style={{
                  width: "18rem",
                  border:
                    selectedOrganization === ""
                      ? "2px solid #ffff"
                      : "2px solid #baff4a",
                  transform:
                    selectedOrganization === "" ? "scale(1.03)" : "scale(1)",
                  transition: "transform 0.2s ease",
                }}
              >
                {
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img
                    className="card-img-top"
                    src={PatterImage}
                    alt="Card image cap"
                  />
                }
                <div className="card-body" style={{ alignItems: "start" }}>
                  <div
                    className="card-title"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    {
                      // @ts-ignore
                      `Personal Account`
                    }
                  </div>

                  {/* <button
                  // @ts-ignore
                  onClick={() => {
                    localStorage.setItem("organization_id", "");
                    navigate("/dashboard");
                  }}
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#baff4a",
                    border: "none",
                    color: "#000",
                  }}
                >
                  Login
                </button> */}
                </div>
              </div>
            )}
            {entities?.map((organization) => (
              <div
                // @ts-ignore
                onClick={() => {
                  handleSettingOrganizatoin(organization);
                }}
                key={organization._id}
                className="card"
                style={{
                  width: "18rem",
                  border:
                    selectedOrganization === organization
                      ? "2px solid #ffff"
                      : "2px solid #baff4a",
                  transform:
                    selectedOrganization === organization
                      ? "scale(1.03)"
                      : "scale(1)",
                  transition: "transform 0.2s ease",
                }}
              >
                {
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img
                    className="card-img-top"
                    src={PatterImage}
                    alt="Card image cap"
                  />
                }
                <div className="card-body" style={{ alignItems: "start" }}>
                  <div
                    className="card-title"
                    style={{ color: "#000", fontWeight: "bolder" }}
                  >
                    {
                      // @ts-ignore
                      organization.name
                    }
                  </div>
                  <p className="card-text" style={{ color: "#282c34" }}>
                    {
                      // @ts-ignore
                      organization.members.length > 0
                        ? // @ts-ignore
                          organization.members.length
                        : 0
                    }
                    members
                  </p>
                  {/* <button
                  // @ts-ignore
                  onClick={() => handleLogin(organization._id)}
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#baff4a",
                    border: "none",
                    color: "#000",
                  }}
                >
                  Login
                </button> */}
                </div>
              </div>
            ))}
          </div>

          <button
            className="btn btn-primary"
            style={{
              backgroundColor: "#baff4a",
              border: "black",
              color: "#000",
            }}
            onClick={() => handleLogin()}
          >
            Proceed
            <span>
              <FaArrowRight />
            </span>
          </button>
        </div>
      ) : (
        <div className="w-100 h-100 position-absolute d-flex flex-row justify-content-center align-items-center">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default SelectOrganization;
