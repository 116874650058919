import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { API_BASE_URL, STRIPE_PUBLICABLE_KEY } from "../../api.config";
import {
  createCheckoutSessionForOrg,
  fetchSeats,
  updateTeamSeats,
  upgradePlusToTeam,
} from "../../utils/api";
import { Spinner } from "react-bootstrap";
import { useData } from "../../utils/DataContext";

//NOTE: Used in Sign up, Add Team Protection, Upgrade Seats (Multiple Subscriptions)
//NOTE: Sign Up && Add Team Protection => share the same UI
//NOTE:   Sign Up has no token saved
//NOTE:   ATP upgrades User.plan plus=>team, creates Organization and makes him admin, delete individual payment

//NOTE: Upgrade Seats => UI is different
//NOTE:   only increases the max_seats for admins' org

const TeamPaymentBox = ({ block }) => {
  const [billingTeam, setBillingTeam] = useState("month");
  // const [region, setRegion] = useState(""); // Initialize with null
  const { region, listMembers, seats, availableSeats, updateSeats } = useData(); // Initialize with null

  const [teamMembers, setTeamMembers] = useState(5); // Default to 5 members
  const navigate = useNavigate();
  const location = useLocation();
  const [pageState, setPageState] = useState(false);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //new
  const _usdPerUserMonthly = 24.99;
  const _usdPerUserQuarterly = 19.99;
  const _gbpPerUserMonthly = 19.99;
  const _gbpPerUserQuarterly = 16.99;

  const userUpgradeConditions =
    !!localStorage.getItem("token") && !!localStorage.getItem("subscription");

  useEffect(() => {
    setBillingTeam(
      localStorage.getItem("billingOption")
        ? localStorage.getItem("billingOption")
        : "month"
    );
    setTeamMembers(
      localStorage.getItem("max_team_members")
        ? Number(localStorage.getItem("max_team_members"))
        : 5
    );
  }, []);

  useEffect(() => {
    // Fetch user's region based on IP address
    setIsLoading(false);
    console.log("ls", location);
    setPageState(location.state?.state === "add-seats");
    // location.state.state === "add-team-protection" ||
    //   location.pathname === "/upgrade-plan"
    // const getRegion = async () => {
    //   try {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     const ipAddress = response.data.ip;
    //     const userRegion = await axios.get(
    //       `https://ipapi.co/${ipAddress}/country/`
    //     );
    //     console.log(userRegion);
    //     setRegion(userRegion.data);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };
    // getRegion();

    // const fetchRegion = async (ipAddress) => {
    //   if (!ipAddress) return; // Don't fetch if ipAddress is not available

    //   try {
    //     const response = await axios.get(
    //       `https://ipapi.co/${ipAddress}/country/`
    //     );
    //     setRegion(response.data);
    //   } catch (error) {
    //     console.error("Error fetching region:", error);
    //   } finally {
    //     // setLoading(false);
    //   }
    // };

    // const fetchIpAddress = async () => {
    //   try {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     const ipAddress = response.data.ip;
    //     await fetchRegion(ipAddress);
    //   } catch (error) {
    //     console.error("Error fetching IP address:", error);
    //   } finally {
    //     // setLoading(false);
    //   }
    // };
    // fetchIpAddress();
  }, [location]);

  const handlePlanSelection = async (totalTeamPrice, billingTeam) => {
    const packageDetails = {
      price: totalTeamPrice,
      billingOption: billingTeam,
      region,
      teamMembers: teamMembers,
      customer_email: localStorage.getItem("email"),
    };
    console.log(location);
    if (location.pathname === "/upgrade-plan") {
      navigate("/signup-team", { state: packageDetails });
      return;
    }

    try {
      let session;
      if (
        location.state?.state === "add-team-protection" ||
        localStorage.getItem("plan") === "none"
      ) {
        session = await createCheckoutSessionForOrg(
          packageDetails,
          "add-team-protection"
        );
      } else if (location.state?.state === "add-seats") {
        const seats = await fetchSeats();
        packageDetails.teamMembers += seats.data.seats;
        session = await createCheckoutSessionForOrg(
          packageDetails,
          "add-seats"
        );
      } else {
        session = await createCheckoutSessionForOrg(packageDetails, "create");
      }
      if (session.status === 200) {
        console.log("Stripe API Response:", session.data.sessionId);
        const { sessionId } = session.data;
        const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`);
        const { error } = (await stripe?.redirectToCheckout({
          sessionId,
        })) ?? { error: "not working" };
        if (error) {
          alert("error occured during payment");
          return;
        }
      }
    } catch (error) {
      alert(error);
      console.log(error);
    }

    // if (!!!localStorage.getItem("token")) {
    //   navigate("/signup-team", { state: { packageDetails } });
    // } else {
    //   navigate("/signup-individual", { state: { packageDetails } });
    // }
  };

  const handleIncrement = () => setTeamMembers((prev) => prev + 1);
  const handleDecrement = () => {
    // minimum 5 seats
    if (teamMembers === 5) {
      return;
    }

    // the user shouldn't be able currently occupied seats
    const currentOccupiedSeats = seats - availableSeats;

    if (teamMembers - currentOccupiedSeats <= 0) {
      return;
    }

    setTeamMembers((prev) => {
      if (location.state?.state === "add-seats") {
        return Math.max(1, prev - 1);
      }
      return Math.max(5, prev - 1);
    });
  };
  let teamPricePerPerson;
  if (region !== "GB") {
    if (billingTeam === "month") {
      teamPricePerPerson = _usdPerUserMonthly;
    } else {
      teamPricePerPerson = _usdPerUserQuarterly;
    }
  } else {
    if (billingTeam === "month") {
      teamPricePerPerson = _gbpPerUserMonthly;
    } else {
      teamPricePerPerson = _gbpPerUserQuarterly;
    }
  }
  let discount = 1;
  if (teamMembers > 9 && teamMembers <= 19) {
    discount = 0.9;
  } else if (teamMembers > 19) {
    discount = 0.85;
  }
  let totalTeamPrice = teamPricePerPerson * teamMembers * discount;
  const upgrade_plus_to_team = async () => {
    try {
      const packageDetails = {
        price: totalTeamPrice,
        billingOption: billingTeam,
        region,
        teamMembers: teamMembers,
        customer_email: localStorage.getItem("email"),
      };
      const resp = await upgradePlusToTeam(packageDetails);

      if (resp.status === 200) {
        const { sessionId } = resp.data;
        const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`);
        const { error } = (await stripe?.redirectToCheckout({
          sessionId,
        })) ?? { error: null };
        if (error) {
          console.log("error occured during payment");
          return;
        }
      }

      setConfirmationBox(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const update_team_seats = async () => {
    try {
      const packageDetails = {
        price: totalTeamPrice,
        billingOption: billingTeam,
        region,
        teamMembers: teamMembers,
        customer_email: localStorage.getItem("email"),
      };

      console.log("packageDetails", packageDetails);

      const resp = await updateTeamSeats(packageDetails);

      if (resp.status === 200) {
        const { sessionId } = resp.data;
        const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`);
        const { error } = (await stripe?.redirectToCheckout({
          sessionId,
        })) ?? { error: null };
      } else {
        console.log("error occured during payment");
        return;
      }

      // setConfirmationBox(false);
      // setIsLoading(false);

      // toast.success(
      //   `${teamMembers} more seat(s) have been added to your organization.`
      // );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const buy_plus = async () => {
    try {
      const packageDetails = {
        price: totalTeamPrice,
        billingOption: billingTeam,
        region,
        teamMembers: teamMembers,
        customer_email: localStorage.getItem("email"),
      };

      const resp = await upgradePlusToTeam(packageDetails);

      if (resp.status === 200) {
        const { sessionId } = resp.data;
        const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`);

        const { error } = (await stripe?.redirectToCheckout({
          sessionId,
        })) ?? { error: null };

        if (error) {
          alert("error occured during payment");
          return;
        }
      }
      setConfirmationBox(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  console.log("reagion: ", region);
  return (
    <div id="main-team-payment-card" className="col-lg-6 mb-6 ">
      <div className="" hidden={!(confirmationBox && userUpgradeConditions)}>
        <ConfirmPayment
          proceed={() => {
            if (localStorage.getItem("plan") === "plus") {
              upgrade_plus_to_team(totalTeamPrice, billingTeam);
            } else if (localStorage.getItem("plan") === "team") {
              console.log("working phew");
              update_team_seats(totalTeamPrice, billingTeam);
            }
          }}
          decline={() => setConfirmationBox(false)}
          details={{
            package:
              region !== "GB"
                ? `USD $${totalTeamPrice.toFixed(2)}/team/${billingTeam}`
                : `GBP £${totalTeamPrice.toFixed(2)}/team/${billingTeam}`,
            seats: teamMembers,
          }}
        />
      </div>
      <div className="card upgrade-card d-flex flex-column justify-content-center">
        {region !== "" && !isLoading ? (
          <div
            className="card-body"
            style={{
              justifyContent: pageState ? "space-evenly" : "space-between",
            }}
          >
            <h5 className="card-title" hidden={pageState}>
              Team plan
            </h5>
            <h5 className="card-title" hidden={!pageState}>
              Members
            </h5>
            <h6 className="card-subtitle mb-2" hidden={pageState}>
              {region !== "GB"
                ? `USD $${totalTeamPrice.toFixed(2)}/team/${billingTeam}`
                : `GBP £${totalTeamPrice.toFixed(2)}/team/${billingTeam}`}
            </h6>
            <h6 className="card-subtitle mb-2" hidden={!pageState}>
              {region !== "GB"
                ? `USD $${totalTeamPrice.toFixed(2)}/${billingTeam}`
                : `GBP £${totalTeamPrice.toFixed(2)}/${billingTeam}`}
            </h6>
            <div className="billing-options mt-3" style={{ display: "flex" }}>
              <label>
                <input
                  type="radio"
                  value="month"
                  checked={billingTeam === "month"}
                  onChange={() => setBillingTeam("month")}
                  style={{ accentColor: "#aaf532", marginRight: "0.5rem" }}
                />
                <span
                  style={{ marginLeft: "1rem", fontFamily: "Cont-regular" }}
                >
                  Monthly - {region !== "GB" ? `$` : `£`}
                  {region !== "GB" ? _usdPerUserMonthly : _gbpPerUserMonthly}
                </span>
              </label>
              <label className="ml-3" style={{ marginLeft: "2rem" }}>
                <input
                  type="radio"
                  value="quarter"
                  checked={billingTeam === "quarter"}
                  onChange={() => setBillingTeam("quarter")}
                  style={{ accentColor: "#aaf532", marginRight: "0.5rem" }}
                />
                <span
                  style={{ marginLeft: "1rem", fontFamily: "Cont-regular" }}
                >
                  Quaterly - {region !== "GB" ? `$` : `£`}
                  {region !== "GB"
                    ? _usdPerUserQuarterly
                    : _gbpPerUserQuarterly}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15%
                  discount
                </span>
              </label>
            </div>
            <div className="mt-4">
              <label htmlFor="team-members" className="form-label">
                Team Members
              </label>
              <div className="input-group">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleDecrement}
                >
                  -
                </button>
                <input
                  style={{
                    color: "#aaf532",
                    backgroundColor: "#000",
                    border: "none",
                  }}
                  type="text"
                  className="form-control text-center"
                  id="team-members"
                  value={teamMembers}
                  // onChange={(e) => setTeamMembers(e.target.value)}
                  min={5}
                  // type="number"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleIncrement}
                >
                  +
                </button>
              </div>
            </div>
            <button
              hidden={pageState}
              className="btn btn-primary btn-block upgrade-button mt-3"
              // onClick={() => {
              //   console.log(userUpgradeConditions);
              //   if (userUpgradeConditions) {
              //     setConfirmationBox(true);
              //     return;
              //   } else {
              //     handlePlanSelection(totalTeamPrice, billingTeam);
              //   }
              // }}
              onClick={() => {
                if (localStorage.getItem("plan") === "plus") {
                  upgrade_plus_to_team();
                } else if (localStorage.getItem("plan") === "team") {
                  console.log("working phew");
                  update_team_seats();
                } else {
                  handlePlanSelection(totalTeamPrice, billingTeam);
                }
                setIsLoading(true);
              }}
              disabled={!region}
            >
              Add Team Protection
            </button>
            <button
              hidden={!pageState}
              className="btn btn-primary btn-block upgrade-button mt-3"
              // onClick={() => {
              //   console.log(userUpgradeConditions);
              //   if (userUpgradeConditions) {
              //     setConfirmationBox(true);
              //     return;
              //   } else {
              //     handlePlanSelection(totalTeamPrice, billingTeam);
              //   }
              // }}
              onClick={() => {
                if (localStorage.getItem("plan") === "plus") {
                  upgrade_plus_to_team(totalTeamPrice, billingTeam);
                } else if (localStorage.getItem("plan") === "team") {
                  console.log("working phew");
                  update_team_seats(totalTeamPrice, billingTeam);
                }
                setIsLoading(true);
              }}
            >
              Add More Seats
            </button>
            <ul className="list-unstyled mt-3 mb-4" hidden={pageState}>
              <li>Everything in Plus, and:</li>
              <li>
                ✔ Higher token limit - everyone on your team can test more
                messages and links
              </li>
              <li>✔ Admin console for team members management</li>
              <li>
                ✔ Detailed Analytics - ROI, cost savings based on number of
                scams/phishing prevented in your organization
              </li>
            </ul>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  fontSize: 16,
                }}
              >
                *10+ users: additional 10% discount on the total price`
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontSize: 16,
                }}
              >
                *20+ users: 15% discount on the total price
              </p>
            </div>

            <p className="mt-2" hidden={pageState}>
              * Minimum 5 users
            </p>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            // style={{ minHeight: "100%", width: "100%" }}
          >
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamPaymentBox;

const ConfirmPayment = ({ proceed, decline, details }) => {
  const [spin, setSpin] = useState(false);
  useEffect(() => {
    setSpin(false);
  }, []);
  const polling = async () => {
    try {
      while (true) {
        const resp = await fetchSeats();
        if (
          resp.data.seats ===
          details.seats + localStorage.getItem("max_team_members")
        ) {
          setSpin(false);
          decline();

          break;
        }
        setTimeout(() => {}, 2000);
      }
    } catch (error) {}
  };
  const handleSpintWithTimer = async () => {
    setSpin(true);
    await polling();
    // setTimeout(() => {
    //   setSpin(false);
    //   decline();
    // }, 5000);
  };
  return (
    <div className="">
      <div
        className="position-absolute z-3"
        style={{
          background: "#fff",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          filter: "blur(35px)",
        }}
      ></div>
      <div
        className="position-absolute z-3 rounded p-5"
        style={{
          backgroundColor: "#bbff4b",
          width: "40%",
          top: "25%",
          left: "25%",
          border: "2px solid black",
        }}
      >
        {spin ? (
          <div className="w-100 h-100 d-flex justify-content-center text-dark">
            <Spinner />
          </div>
        ) : (
          <div className="">
            <h3 style={{ color: "black" }}>
              Are you sure you want to upgrade to this plan?
            </h3>
            <p style={{ color: "black" }}>
              You will be charged with the following amount at the end of
              billing cycle and your subscription will be upgraded and adjusted
              according to your billing cycle:
            </p>
            <p style={{ color: "black" }}>
              Plan: <b>Team Plan</b>
            </p>
            <p style={{ color: "black" }}>
              Amount: <b>{details.package}</b>
            </p>
            <p style={{ color: "black" }}>
              Added Seats: <b>{details.seats}</b>
            </p>
            <button
              className="btn btn-success"
              onClick={() => {
                proceed();
                handleSpintWithTimer();
              }}
            >
              Yes
            </button>
            <button className="btn btn-danger" onClick={decline}>
              No
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
