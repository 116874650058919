import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { API_BASE_URL } from "../../api.config";

const CompleteRegistration = () => {
  const [FullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");
  if (!token) {
    navigate('/login')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/complete-registration`,
        { token, FullName, password }
      );
      setSuccess(response.data.message);
      setTimeout(() => navigate("/login"), 2000);
    } catch (err) {
      setError("An error occurred while completing the registration.");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
    setPasswordError("");
  };

  return (
    <Container
      style={{
        width: "50%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="mt-0"
    >
      <div className="text-center mb-4">
        <h1>Complete Your Registration</h1>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="FullName">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your User name"
            value={FullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password" className="mt-3">
          <Form.Label>Create Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            placeholder="Enter your password"
            onChange={handlePasswordChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="passwordConfirm" className="mt-3">
          <Form.Label>Re-enter Password</Form.Label>
          <Form.Control
            type="password"
            value={passwordConfirm}
            placeholder="Re-enter your password"
            onChange={handlePasswordConfirmChange}
            required
          />
        </Form.Group>

        {passwordError && <Alert variant="danger">{passwordError}</Alert>}

        <div className="text-center">
          <Button
            className="btn btn-outline-success text-center text-white w-auto h-auto mt-5"
            type="submit"
            variant="success"
          >
            Complete Registration
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CompleteRegistration;
