import axios from "axios";
import { API_BASE_URL } from "../api.config";
const getConfig = () => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  if (localStorage.getItem("organization_id") !== "") {
    config["params"] = {
      organizationId: localStorage.getItem("organization_id"),
    };

    return config;
  }

  return config;
};

export const listTeamMembers = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/team/list-team-members`,
      getConfig()
    );

    return response;
  } catch (error) {
    console.error("Error fetching members:", error);
  }
};

export const listUrls = async (userId) => {
  const response = await axios.get(
    `${API_BASE_URL}/check-url/${userId}`,
    getConfig()
  );
  return response;
};

// export const makeTeamAdmin = async () => {
//   // console.log(localStorage.getItem("max_team_members"))
//   try {
//     const response = await axios.put(
//       `${API_BASE_URL}/user/make-team-admin`,
//       {
//         teamMembersCount: Number(localStorage.getItem("max_team_members")),
//       },
//       {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       }
//     );
//     localStorage.removeItem("max_team_members");
//     localStorage.setItem("role", response.data.role);
//     localStorage.setItem("plan", response.data.plan);
//     localStorage.setItem("organization_id", response.data.organizationId);
//     localStorage.setItem("user_id", response.data.userId);
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const fetchOrgLen = async (arr) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/organizations/get-organizations`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          organizationIds: arr, // The array of organization IDs you want to fetch
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch organizations");
    }

    let data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching organizations:", error);
  }
};

export const fetchSeats = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/organizations/seats`,
      {
        params: {
          organizationId: localStorage.getItem("organization_id"),
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const createCheckoutSessionForOrg = async (
  { price, billingOption, region, teamMembers, customer_email, quantity },
  method
) => {
  const payload = {
    method,
    plan: "team",
    price,
    billingOption,
    region,
    customer_email,
    teamMembers,
  };
  if (
    localStorage.getItem("stripeCustomerId") &&
    localStorage.getItem("stripeCustomerId") !== ""
  ) {
    payload["customer"] = localStorage.getItem("stripeCustomerId");
    payload["subscription"] = localStorage.getItem("subscription");
  }

  console.log(payload);
  const session = await axios.post(
    `${API_BASE_URL}/api/stripe/create-checkout-session`,
    {
      payloadData: payload,
      subscription: localStorage.getItem("subscription"),
    }
  );

  return session;
};
export const createCheckoutSessionForInd = async (
  { region, customer_email },
  method
) => {
  const payload = {
    method,
    plan: "plus",
    billingOption: "month",
    region,
    customer_email,
  };

  console.log(payload);
  const session = await axios.post(
    `${API_BASE_URL}/api/stripe/create-checkout-session`,
    {
      payloadData: payload,
    }
  );

  return session;
};
//localstorage
// export const createStripeCheckoutSession = async (
//   current_plan,
//   method,
//   payload
// ) => {
//   //when lawaris => add-individual-proteciton, add-team-protection
//   //when individual => add-team-protection
//   //when => add-seats
//   try {
//     let payload;
//     let session;
//     if (current_plan === "plus") {
//       //create
//       //add-team-ptotection
//       if (method === "create") {
//       } else if (method === "add-team-protection") {
//       }
//     } else if (current_plan === "team") {
//       //create
//       //add-seats
//       if (method === "create") {
//       } else if (method === "add-seats") {
//       }
//     } else if (current_plan === "none") {
//       //create
//       if (method === "create") {
//       }
//     }
//     session = await axios.post(
//       `${API_BASE_URL}/api/stripe/create-checkout-session`,
//       {
//         payloadData: payload,
//       }
//     );
//     return session;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };

export const createUser = async (payload) => {
  return await axios.post(`${API_BASE_URL}/auth/signup`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const readUser = async (id) => {
  return await axios.get(`${API_BASE_URL}/user/read/${id}`);
};

export const login = async (loginEmail, loginPassword) => {
  return await axios.post(
    `${API_BASE_URL}/auth/login`,
    {
      email: loginEmail,
      password: loginPassword,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const refresh = async (loginEmail) => {
  return await axios.post(
    `${API_BASE_URL}/auth/refresh`,
    {
      email: loginEmail,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const localize = async (response) => {
  return await new Promise((res) => {
    localStorage.setItem("token", response.data.accessToken);
    localStorage.setItem("user_id", response.data.user_id);
    localStorage.setItem("plan", response.data.plan);
    localStorage.setItem("Full_name", response.data.FullName);
    localStorage.setItem("email", response.data.email);
    localStorage.setItem("role", response.data.role);
    localStorage.setItem("subscription", response.data.subscription);
    if (response.data.billingOption) {
      localStorage.setItem("billingOption", response.data.billingOption);
    }

    if (response.data.organizationId) {
      localStorage.setItem("organization_id", response.data.organizationId);
    } else {
      localStorage.setItem("organization_id", "");
    }
    if (response.data.stripe_customer_id && response.data.subscription) {
      localStorage.setItem(
        "stripeCustomerId",
        response.data.stripe_customer_id
      );
      localStorage.setItem("subscription", response.data.subscription);
    }
    if (response.data.max_team_members) {
      localStorage.setItem("max_team_members", response.data.max_team_members);
    }
    res({ message: "success" });
  });
};

export const getSubscriptionHistory = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/stripe/subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          stripeCustomerId: localStorage.getItem("stripeCustomerId"),
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createCustomerPortalSession = async (customerId) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/stripe/create-customer-portal-session`,
      {
        customerId,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTeamSeats = async ({
  billingOption,
  region,
  teamMembers,
  customer_email,
}) => {
  //it will use the same subscription_id and upgrade the plan and assign seats
  try {
    const resp = await axios.post(
      `${API_BASE_URL}/api/stripe/update-team-seats`,
      {
        payloadData: {
          billingOption,
          region,
          teamMembers,
          customer_email,
          subscription: localStorage.getItem("subscription"),
        },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    return resp;
    // if (resp.status === 200) {
    //   const polling = async () => {
    //     const prevseats = Number(localStorage.getItem('max_team_members'))
    //     try {
    //       while (true) {
    //         const response = await fetchSeats()

    //         console.log(
    //           'response.data.seats === prevseats + teamMembers',
    //           response.data.seats,
    //           prevseats,
    //           teamMembers
    //         )
    //         console.log(response.data.seats === prevseats + teamMembers)
    //         if (response.data.seats === prevseats + teamMembers) {
    //           // await new Promise((resolve) => setTimeout(resolve, 5000));
    //           const response = await refresh(localStorage.getItem('email'))
    //           const message = await localize(response)
    //           if (message.message === 'success') {
    //             // window.location.href = resp.data.url
    //             // window.location.reload();
    //             break
    //           }
    //         }
    //         await new Promise((res) => setTimeout(res, 3000))
    //       }
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   }
    //   await polling()
    // }
  } catch (error) {
    console.log(error);
  }
};

export const upgradePlusToTeam = async ({
  billingOption,
  region,
  teamMembers,
  customer_email,
}) => {
  console.log(billingOption, region, teamMembers, customer_email);
  try {
    const resp = await axios.post(
      `${API_BASE_URL}/api/stripe/upgrade-plus-to-team`,
      {
        payloadData: {
          billingOption,
          region,
          teamMembers,
          customer_email,
          subscription: localStorage.getItem("subscription"),
        },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    // if (resp.status === 200) {
    //   await new Promise((resolve) => setTimeout(resolve, 5000));
    //   const response = await refresh(localStorage.getItem("email"));
    //   const message = await localize(response);
    //   if (message.message === "success") {
    //     window.location.href = resp.data.url;
    //     // window.location.reload();
    //   }
    // }
    return resp;
  } catch (error) {
    console.log(error);
  }
};
