import React, { useState, useEffect } from "react";
import "./UpgradePlan.css";
import Logo from "../../assets/Logo/Logo_hor_green.svg";
// import axios from "axios";
// import { Spinner } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { createUser } from "../../utils/api";
import IndividualPaymentBox from "./individual.payment";
import TeamPaymentBox from "./team.payment";
import { DataProvider } from "../../utils/DataContext";

const UpgradePlan = () => {
  // const [billingIndividual, setBillingIndividual] = useState("month");
  // const [region, setRegion] = useState(null); // Initialize with null
  // const [loading, setLoading] = useState(true);
  // const [teamMembers, setTeamMembers] = useState(2); // Default to 2 members

  // const navigate = useNavigate();

  // useEffect(() => {
  // Fetch user's region based on IP address
  // axios
  //   .get("https://api.ipify.org?format=json")
  //   .then((response) => {
  //     const ipAddress = response.data.ip;
  //     axios
  // .get(`https://ipapi.co/${ipAddress}/country/`)
  // .then((response) => {
  // const userRegion = response.data.toLowerCase();
  // @ts-ignore
  // setRegion(userRegion === "gb" ? "UK" : "US");
  // console.log("Region:", userRegion);
  // setLoading(false);
  // })
  // .catch((error) => {
  // console.error("Error fetching region:", error);
  // setLoading(false);
  // });
  // })
  // .catch((error) => {
  // console.error("Error fetching IP address:", error);
  // setLoading(false);
  //     });
  // }, []);

  // const handlePlanSelection = (plan, price, billingOption) => {
  //   const packageDetails = {
  //     plan,
  //     price,
  //     billingOption,
  //     region,
  //   };
  //   console.log(packageDetails);
  //   navigate("/signup-individual", { state: { packageDetails } });
  // };

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         height: "100%",
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  // const individualPricePerPerson = region === "UK" ? 4.99 : 5.99;
  // const totalIndividualPrice = individualPricePerPerson;

  return (
    <div
      className="container overflow-auto position-relative w-100 bg-black h-100"
      style={{
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 5,
        padding: "20px",
        maxWidth: "100%",
      }}
    >
      <div className="row justify-content-center mb-4">
        <img style={{ width: "30rem" }} src={Logo} alt="Upgrade plan" />
      </div>
      <h2 className="mb-4 text-center">Choose your plans</h2>
      <div className="row ">
        <DataProvider>
          <IndividualPaymentBox />
          <TeamPaymentBox />
        </DataProvider>
      </div>
    </div>
  );
};

export default UpgradePlan;
