import React, { useState } from 'react'
import './signupform.css'
import { useLocation, useNavigate } from 'react-router-dom'
import LOGO from '../../assets/Favicon/64x64.svg'
import axios from 'axios'
import { API_BASE_URL, STRIPE_PUBLICABLE_KEY } from '../../api.config'
import { Alert } from 'react-bootstrap'
import { loadStripe } from '@stripe/stripe-js'
import { createCheckoutSessionForOrg, createUser } from '../../utils/api'

const SignUpFormTeam = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [FullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [confirmShowPassword, setConfirmShowPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(true)
  const [organisationType, setOrganisationType] = useState('')

  const packageDetails = location.state || {
    price: 0,
    region: '',
  }

  const handleSignup = async (e) => {
    e.preventDefault()
    if (password !== passwordConfirm) {
      setPasswordError('Passwords do not match.')
      return
    }

    try {
      const response = await createUser({
        FullName,
        email,
        password,
        plan: 'team',
        MaxTeamMembersCount: packageDetails.teamMembers,
      })

      if (response.status === 201) {
        const payloadData = {
          plan: packageDetails.plan,
          region: packageDetails.region,
          billingOption: packageDetails.billingOption,
          teamMembers: packageDetails.teamMembers,
          email: email,
          fullName: FullName,
        }

        try {
          console.log(packageDetails)
          const stripeResponse = await createCheckoutSessionForOrg(
            { ...packageDetails, customer_email: email },
            'create'
          )

          if (stripeResponse.status === 200) {
            console.log('Stripe API Response:', stripeResponse.data.sessionId)

            const { sessionId } = stripeResponse.data
            const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`)
            const { error } = (await stripe?.redirectToCheckout({
              sessionId,
            })) ?? { error: null }

            if (error) {
              console.error('Stripe error:', error)
            }
          }
        } catch (error) {
          console.log(error)
          setPasswordError(error.response.data.error)
        }
      }
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response?.data?.error)
      // setPasswordError(error)
    }
  }

  // const handleSignUpWithGoogle = async () => {
  //   console.log('Sign up with Google')
  // }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    setPasswordError('')
  }

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value)
    setPasswordError('')
  }

  return (
    <div className='signupFormMain'>
      <div className='signupFormContainer'>
        <img src={LOGO} alt='' />

        <h1 style={{ width: '100%', margin: '1rem' }}>
          Create your Team account
        </h1>
        <form onSubmit={handleSignup}>
          <input
            type='text'
            name='FullName'
            id='FullName'
            placeholder='Enter Full Name'
            value={FullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />

          <input
            type='email'
            name='email'
            id='email'
            placeholder='Enter Email'
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            required
          />

          <select
            name='organisationType'
            id='organisationType'
            value={organisationType}
            onChange={(e) => setOrganisationType(e.target.value)}
            required
          >
            <option value='' disabled>
              Select Type of Organisation
            </option>
            <option value='Startup'>Startup</option>
            <option value='SME'>SME</option>
            <option value='Enterprise'>Enterprise</option>
            <option value='Family'>Family</option>
            <option value='Educational Institution or Non-profit'>
              Educational Institution or Non-profit
            </option>
          </select>

          <div id='signupPassword'>
            <input
              type={showPassword ? 'password' : 'text'}
              name='password'
              id='password'
              placeholder='Enter password'
              value={password}
              onChange={handlePasswordChange}
              required
              autoComplete='password'
            />
            <i onClick={() => setShowPassword(!showPassword)}>
              {!showPassword ? (
                <svg
                  width={26}
                  height={26}
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g
                    stroke='#202123'
                    strokeWidth={0.792}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7Z' />
                    <path d='M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' />
                  </g>
                  <title>Show Password</title>
                </svg>
              ) : (
                <svg
                  width={26}
                  height={26}
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  stroke='#000'
                >
                  <path
                    d='M20 14.834C21.308 13.332 22 12 22 12s-3.636-7-10-7a8.595 8.595 0 0 0-2 .236M12 9a2.995 2.995 0 0 1 3 3M3 3l18 18m-9-6a2.997 2.997 0 0 1-2.959-2.5M4.147 9c-.308.345-.585.682-.828 1C2.453 11.128 2 12 2 12s3.636 7 10 7c.341 0 .675-.02 1-.058'
                    stroke='#202123'
                    strokeWidth={0.768}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <title>Hide Password</title>
                </svg>
              )}
            </i>
          </div>

          <div id='signupPassword'>
            <input
              type={confirmShowPassword ? 'text' : 'password'}
              name='passwordConfirm'
              id='passwordConfirmc'
              placeholder='Confirm password'
              value={passwordConfirm}
              onChange={handlePasswordConfirmChange}
              required
              autoComplete='password'
            />
            <i
              onClick={() =>
                setConfirmShowPassword(
                  // @ts-ignore
                  !confirmShowPassword
                )
              }
            >
              {confirmShowPassword ? (
                <svg
                  width={26}
                  height={26}
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g
                    stroke='#202123'
                    strokeWidth={0.792}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7Z' />
                    <path d='M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' />
                  </g>
                  <title>Show Password</title>
                </svg>
              ) : (
                <svg
                  width={26}
                  height={26}
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  stroke='#000'
                >
                  <path
                    d='M20 14.834C21.308 13.332 22 12 22 12s-3.636-7-10-7a8.595 8.595 0 0 0-2 .236M12 9a2.995 2.995 0 0 1 3 3M3 3l18 18m-9-6a2.997 2.997 0 0 1-2.959-2.5M4.147 9c-.308.345-.585.682-.828 1C2.453 11.128 2 12 2 12s3.636 7 10 7c.341 0 .675-.02 1-.058'
                    stroke='#202123'
                    strokeWidth={0.768}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <title>Hide Password</title>
                </svg>
              )}
            </i>
          </div>

          {passwordError && (
            <Alert variant='danger' style={{ width: 'auto' }}>
              {passwordError}
            </Alert>
          )}

          <button type='submit'>Sign Up</button>
          {errorMessage.trim() !== '' && (
            <span style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              <span
                className='signupFormContainer span'
                style={{ width: 'auto' }}
              >
                {errorMessage}
              </span>
              <a
                style={{ textDecoration: 'underline', color: '#bbff4b' }}
                href='/login'
              >
                Login
              </a>
            </span>
          )}
        </form>
        {/* <div className="signupSeparator">OR</div>
        <button id="signupWithGoggle" onClick={handleSignUpWithGoogle}>
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width={20}
              height={20}
            >
              <path
                fill="#fbbb00"
                d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
              />
              <path
                fill="#518ef8"
                d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
              />
              <path
                fill="#28b446"
                d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
              />
              <path
                fill="#f14336"
                d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
              />
            </svg>
          </i>
          Continue with Google
        </button> */}
      </div>

      <div className='individualplanDetails'>
        <div className='col-md-12 mb-12'>
          <div className='card upgrade-card'>
            <div className='card-body'>
              <h5 className='card-title'>Team plan</h5>
              <h6 className='card-subtitle mb-2'>
                {/* {region === "US"
                  ? `USD $${totalTeamPrice.toFixed(2)}/team/${billingTeam}`
                  : `GBP £${totalTeamPrice.toFixed(2)}/team/${billingTeam}`} */}
              </h6>
              <div className='billing-options mt-3'>
                <label>
                  <span
                    style={{ marginLeft: '1rem', fontFamily: 'Cont-regular' }}
                  >
                    <h1>Price: {packageDetails.price} </h1>
                  </span>
                </label>
              </div>
              <div className='mt-4'>
                <label htmlFor='team-members' className='form-label'>
                  <h1>Team Members</h1>
                </label>
                <div className='input-group'>
                  <h1
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: '#aaf532',
                      backgroundColor: '#000',
                      border: 'none',
                    }}
                  >
                    {packageDetails.teamMembers}
                  </h1>
                </div>
              </div>
              <button
                className='btn btn-primary btn-block upgrade-button mt-3'
                onClick={() => {
                  navigate('/upgrade-plan')
                }}
              >
                Change Plan
              </button>

              <ul className='list-unstyled mt-3 mb-4'>
                <li>Everything in Plus, and:</li>
                <li>
                  ✔ Higher token limit - everyone on your team can test more
                  messages and links
                </li>
                <li>✔ Admin console for team members management</li>
                <li>✔ Team data excluded from training by default</li>
              </ul>
              <p className='mt-2'>* Price billed annually, minimum 2 users</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpFormTeam
