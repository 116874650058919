import React, { useEffect, useState } from "react";
import { Card, Tooltip } from "react-bootstrap";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import { API_BASE_URL } from "../../../api.config";
import axios from "axios";

const Total_Messages_and_Links_Tested = ({ year }) => {
  const [savedUrls, setsavedUrls] = useState(0);
  const [totalPhishing, setTotalPhishing] = useState(0);
  const [isDataAvailable, setIsDataAvailable] = useState(false); // New state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const organizationId = localStorage.getItem("organization_id");

        const urlsResponse = await axios.post(
          `${API_BASE_URL}/analytics/total-urls`,
          { organizationId, year },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const saved = urlsResponse.data.savedUrls || 0;
        const phishing = urlsResponse.data.phishingUrls || 0;

        setsavedUrls(saved);
        setTotalPhishing(phishing);
        setIsDataAvailable(saved > 0 || phishing > 0); // Check if data is available
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [year]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      {isDataAvailable ? (
        <PieChart
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Pie
            data={[
              { name: "Not scam/phishing", value: savedUrls },
              { name: "Scam/phishing", value: totalPhishing },
            ]}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="red"
            label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
              const RADIAN = Math.PI / 180;
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              return (
                <text
                  x={x}
                  y={y}
                  fill="black"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {`${value}`}
                </text>
              );
            }}
          >
            <Cell key="cell-0" fill="#66BB6A" />
            <Cell key="cell-1" fill="#EF5350" />
          </Pie>

          <Tooltip />
          <Legend />
        </PieChart>
      ) : (
        // Render placeholder when no data is available
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: "16px",
            color: "#999",
          }}
        >
          No data available
        </div>
      )}
    </ResponsiveContainer>
  );
};

export default Total_Messages_and_Links_Tested;
