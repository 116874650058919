import React, { forwardRef, useState } from "react";
import "./signupform.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO from "../../assets/Favicon/64x64.svg";

import axios from "axios";
import { API_BASE_URL, STRIPE_PUBLICABLE_KEY } from "../../api.config";
import { Alert } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSessionForInd } from "../../utils/api";
import DatePicker from "react-datepicker";
import { range, getYear } from "date-fns";

const SignUpFormIndividual = (props) => {
  const location = useLocation();
  const [FullName, setFullName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [confirmShowPassword, setConfirmShowPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const generateYears = (startYear, endYear) => {
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => startYear + i
    );
  };

  const years = generateYears(1990, getYear(new Date()) + 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonth = (date) => {
    return date.getMonth();
  };
  // const selectedPlan = location.state ? location.state.selectedPlan : null;
  const packageDetails = location?.state?.packageDetails || { price: 0 };

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      if (!location.state.packageDetails) {
        alert(
          "Package Details have not been selected. Please visit the previous page and select a plan"
        );
        return;
      }
      console.log(packageDetails);
      const response = await axios.post(
        `${API_BASE_URL}/auth/signup`,
        {
          FullName,
          email,
          password,
          plan: "plus",
          dateOfBirth,
          paymentMethodId,
          subscription: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        const payloadData = {
          region: packageDetails.region,
          customer_email: email,
        };

        // const stripeResponse = await axios.post(
        //   `${API_BASE_URL}/api/stripe/create-checkout-session`,
        //   {
        //     payloadData: payloadData,
        //   }
        // );
        console.log(payloadData);
        const stripeResponse = await createCheckoutSessionForInd(
          payloadData,
          "create"
        );

        if (stripeResponse.status === 200) {
          console.log("Stripe API Response:", stripeResponse.data.sessionId);

          const { sessionId } = stripeResponse.data;
          const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`);
          const { error } = (await stripe?.redirectToCheckout({
            sessionId,
          })) ?? { error: { response: { data: { error: null } } } };

          if (error) {
            console.error("Stripe error:", error);
            // @ts-ignore
            setPasswordError(error.response.data.error);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setPasswordError(error.response.data.error);
    }
  };
  const handleSignUpWithGoggle = async () => {
    console.log("Sign up with google");
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
    setPasswordError("");
  };
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button
        type="button"
        className={className}
        style={{ textAlign: "left", paddingLeft: 5 }}
        onClick={onClick}
        ref={ref}
      >
        <div
          style={{
            width: "100%",
            paddingLeft: 5,
            color: value ? "#202123" : "#b1b3b5",
          }}
        >
          {value ? value : "Date of birth"}
        </div>
      </button>
    )
  );
  return (
    <div className="signupFormMain">
      <div className="signupFormContainer">
        <img src={LOGO} alt="" />
        <h1>Create your Individual account</h1>
        <form onSubmit={handleSignup}>
          <input
            type="FullName"
            name="FullName"
            id="FullName"
            placeholder="Enter Full Name"
            value={FullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />

          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            selected={dateOfBirth}
            onChange={(date) => setDateOfBirth(date)}
            customInput={
              <ExampleCustomInput className="c2" value={dateOfBirth} />
            }
          />
          <div id="signupPassword">
            <input
              type={showPassword ? "password" : "text"}
              name="password"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="password"
            />

            {/* eye icon */}
            <i onClick={() => setShowPassword(!showPassword)}>
              {!showPassword ? (
                <svg
                  width={26}
                  height={26}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#202123"
                    strokeWidth={0.792}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7Z" />
                    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </g>
                  <title>Show Password</title>
                </svg>
              ) : (
                <svg
                  width={26}
                  height={26}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#000"
                >
                  <path
                    d="M20 14.834C21.308 13.332 22 12 22 12s-3.636-7-10-7a8.595 8.595 0 0 0-2 .236M12 9a2.995 2.995 0 0 1 3 3M3 3l18 18m-9-6a2.997 2.997 0 0 1-2.959-2.5M4.147 9c-.308.345-.585.682-.828 1C2.453 11.128 2 12 2 12s3.636 7 10 7c.341 0 .675-.02 1-.058"
                    stroke="#202123"
                    strokeWidth={0.768}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <title>Hide Password</title>
                </svg>
              )}
            </i>
          </div>

          <div id="signupPassword">
            <input
              type={confirmShowPassword ? "text" : "password"}
              name="password"
              id="passwordc"
              value={passwordConfirm}
              placeholder="Confirm password"
              onChange={handlePasswordConfirmChange}
              required
              autoComplete="password"
            />

            {/* eye icon */}
            <i
              onClick={() =>
                setConfirmShowPassword(
                  // @ts-ignore
                  !confirmShowPassword
                )
              }
            >
              {confirmShowPassword ? (
                <svg
                  width={26}
                  height={26}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#202123"
                    strokeWidth={0.792}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7Z" />
                    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </g>
                  <title>Show Password</title>
                </svg>
              ) : (
                <svg
                  width={26}
                  height={26}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#000"
                >
                  <path
                    d="M20 14.834C21.308 13.332 22 12 22 12s-3.636-7-10-7a8.595 8.595 0 0 0-2 .236M12 9a2.995 2.995 0 0 1 3 3M3 3l18 18m-9-6a2.997 2.997 0 0 1-2.959-2.5M4.147 9c-.308.345-.585.682-.828 1C2.453 11.128 2 12 2 12s3.636 7 10 7c.341 0 .675-.02 1-.058"
                    stroke="#202123"
                    strokeWidth={0.768}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <title>Hide Password</title>
                </svg>
              )}
            </i>
          </div>
          {passwordError && (
            <Alert variant="danger" style={{ width: "auto" }}>
              {passwordError}
            </Alert>
          )}

          <button className="signup-btn" type="submit">
            Sign Up
          </button>
          {errorMessage.trim() !== " " && (
            <span style={{ width: "auto" }}>{errorMessage}</span>
          )}
        </form>
        <p
          style={{
            color: "#fff",
          }}
        >
          Already have an account?{" "}
          <Link
            style={{
              color: "#fff",
            }}
            to={"/login"}
          >
            Log in
          </Link>
        </p>
      </div>
      <div className="individualplanDetails">
        <div className="col-md-12 mb-12">
          <div className="card upgrade-card">
            <div className="card-body">
              <h5 className="card-title">Individual plan</h5>
              <h6 className="card-subtitle mb-2">
                {/* {region === "US"
                  ? `USD $${totalTeamPrice.toFixed(2)}/team/${billingTeam}`
                  : `GBP £${totalTeamPrice.toFixed(2)}/team/${billingTeam}`} */}
              </h6>
              <div className="billing-options mt-3">
                <label>
                  <span
                    style={{ marginLeft: "1rem", fontFamily: "Cont-regular" }}
                  >
                    <h1>Price: {packageDetails.price} </h1>
                  </span>
                </label>
              </div>
              <div className="mt-4">
                <label htmlFor="team-members" className="form-label">
                  <h1
                    style={{
                      color: "#aaf532",
                    }}
                  >
                    Individual Account
                  </h1>
                </label>
                <div className="input-group">
                  {/* <h1
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "#aaf532",
                      backgroundColor: "#000",
                      border: "none",
                    }}
                  >
                    {packageDetails.teamMembers}
                  </h1> */}
                </div>
              </div>
              <button
                className="btn btn-primary btn-block upgrade-button mt-3"
                onClick={() => {
                  navigate("/upgrade-plan");
                }}
              >
                Change Plan
              </button>

              <ul className="list-unstyled mt-3 mb-4">
                <li>Everything in Plus, and:</li>
                <li>
                  ✔ Higher token limit - everyone on your team can test more
                  messages and links
                </li>
                <li>✔ Admin console for team members management</li>
                <li>✔ Team data excluded from training by default</li>
              </ul>
              {/* <p className="mt-2">* Price billed annually, minimum 2 users</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpFormIndividual;
