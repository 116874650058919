import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <div className="d-flex justify-content-center w-100 align-items-center">

    <div className="d-flex flex-column align-items-center">
      <h1>Payment Failed</h1>
      <p>There was an issue with your payment. Please try again.</p>
      {/* Display additional details or retry options */}
      <Link to={"/dashboard"} className="btn btn-success">
        Back to Dashboard
      </Link>
    </div>
    </div>
  );
};

export default PaymentFailed;
