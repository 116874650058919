import axios from "axios";
import React, { PureComponent, useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  Label,
} from "recharts";
import { API_BASE_URL } from "../../../api.config";

const Number_of_Phishing_Attempts_Blocked = ({ year }) => {
  const token = localStorage.getItem("token");
  const [graphData, setGraphData] = useState([]);

  const data = [
    {
      name: "Page A",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "Page B",
      uv: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "Page C",
      uv: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "Page D",
      uv: 1480,
      pv: 1200,
      amt: 1228,
      cnt: 480,
    },
    {
      name: "Page E",
      uv: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
    {
      name: "Page F",
      uv: 1400,
      pv: 680,
      amt: 1700,
      cnt: 380,
    },
  ];

  const newData = [
    {
      month: "Jan",
      blocked: 10,
      c_blocked: 12,
    },
  ];
  useEffect(() => {
    const data = async () => {
      const data = await axios.get(
        `${API_BASE_URL}/graph/number_of_phishing_attempts_blocked`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            year: year,
          },
        }
      );
      console.log("graphing", data.data);
      setGraphData(data.data);
    };
    data();
  }, []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={graphData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="month">
          <Label position={"end"} value="Months" dy={20}></Label>
        </XAxis>
        <YAxis
          yAxisId="left"
          stroke="#413ea0"
          tickFormatter={(item, ind) => {
            if (Math.abs(item) >= 1000000) {
              return `${(item / 1000000).toFixed(1)}M`;
            } else if (item >= 1000) {
              return `${(item / 1000).toFixed(1)}k`;
            } else {
              return item;
            }
          }}
        >
          <Label
            position={"insideLeft"}
            value="Number of Phishing Attempts Blocked"
            angle={-90}
            // Adjust this value as needed
            style={{
              textAnchor: "middle",
              fontSize: "80%",
              fill: "#413ea0",
            }}
          ></Label>
        </YAxis>
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#ff7300"
          tickFormatter={(item, ind) => {
            if (Math.abs(item) >= 1000000) {
              return `${(item / 1000000).toFixed(1)}M`;
            } else if (item >= 1000) {
              return `${(item / 1000).toFixed(1)}k`;
            } else {
              return item;
            }
          }}
        >
          {" "}
          <Label
            position={"insideRight"}
            value="Cumulative Number of Phishing Attempts Blocked"
            angle={-90}
            style={{
              textAnchor: "middle",
              fontSize: "80%",
              fill: "#ff7300",
            }}
          ></Label>
        </YAxis>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Bar
          yAxisId="left"
          dataKey="phishingCount"
          barSize={20}
          fill="#413ea0"
          name={"Monthly Phishing Attempts Blocked"}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="phishingCount"
          stroke="#ff7300"
          name={"Cumulative Phishing Attempts Blocked"}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Number_of_Phishing_Attempts_Blocked;
