import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../api.config";
import { Spinner, Button } from "react-bootstrap";
import { BiCheckCircle } from "react-icons/bi"; // Example of using BiCheckCircle for tick icon
import axios from "axios";
// import { makeTeamAdmin } from "../../utils/api";
import { FaArrowRight } from "react-icons/fa";
import { localize, refresh } from "../../utils/api";

const PaymentSuccess = () => {
  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        // await axios.put(
        //   `${API_BASE_URL}/user`,
        //   {
        //     changes: {
        //       role: "admin",
        //       plan: "team",
        //     },
        //     members_count: teamMembers,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   }
        // );
        const sessionId = params.get("session_id");

        // const paymentSuccess = await axios.get(
        //   `${API_BASE_URL}/api/stripe/payment-success/${sessionId}`
        // );
        const paymentSuccess = "";
        console.log(paymentSuccess);
        const response = await fetch(
          `${API_BASE_URL}/api/stripe/session-details/${sessionId}`
        );
        setPaymentDetails(true);
        const data = await response.json();
        setPaymentDetails(data);
        console.log("Payment details:", data);
      } catch (error) {
        console.error("Error fetching payment details:", error);
        navigate("/payment-failed");
      }
    };
    if (localStorage.getItem("role") !== "admin") {
      // makeTeamAdmin();
    }
    fetchPaymentDetails();
  }, []);

  if (!paymentDetails) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  const handleSignupRedirect = async () => {
    try {
      // if (
      //   localStorage.getItem("email") ||
      //   localStorage.getItem("email") !== ""
      // ) {
      //   const response = await refresh(localStorage.getItem("email"));
      //   const message = await localize(response);
      //   console.log(message.message);
      //   if (message.message === "success") {
      //     // navigate("/dashboard");
      //   }
      // } else {
      localStorage.clear();
      navigate("/dashboard");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center h-50 w-50">
        <BiCheckCircle
          style={{
            color: "green",
            fontSize: "6rem",
            verticalAlign: "top",
            top: "-35px",
            position: "relative",
          }}
        />
        <h1 style={{ top: "-22px", position: "relative" }}>
          Payment Successful
        </h1>
        <p
          style={{
            textAlign: "center",
            top: "-17px",
            position: "relative",
            marginBottom: 0,
          }}
        >
          Welcome to your Protected Headquater.
        </p>
        <p style={{ textAlign: "center", top: "-17px", position: "relative" }}>
          You can now start surfing Safely 🔒
        </p>
        <Button
          onClick={handleSignupRedirect}
          style={{
            color: "black",
            background: "rgb(187, 255, 75),",
            border: "1px solid black",
          }}
        >
          Continue <FaArrowRight />
        </Button>
        {/* <p>Your account is now protected</p> */}
        {/* <div>
        <p>Plan: {paymentDetails.plan}</p>
        <p>Region: {paymentDetails.region}</p>
        <p>Billing Option: {paymentDetails.billingOption}</p>
      </div> */}
      </div>
    </div>
  );
};

export default PaymentSuccess;
