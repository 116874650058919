import React, { PureComponent, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { API_BASE_URL } from "../../../api.config";
import axios from "axios";

const Tested_Messages_Links_and_Detected_Scams_per_Team_Member = ({
  interval,
  year,
}) => {
  const token = localStorage.getItem("token");
  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    const data = async () => {
      const data = await axios.post(
        `${API_BASE_URL}/graph/tested_messages_links_and_detected_scams_per_team_member`,
        { year },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            interval: interval,
            year,
          },
        }
      );
      console.log("graphing", data.data);
      setGraphData(data.data);
    };
    data();
  }, []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        // width={500}
        // height={400}
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="fullName" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          name={"Total Count"}
          dataKey="urlCount"
          fill="#00a0ff"
          // activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        <Bar
          name={"Scam/phishing"}
          dataKey="phishingCount"
          fill="#ff0a00"
          // activeBar={<Rectangle fill="gold" stroke="purple" />}
        />
        <Brush dataKey="fullName" height={30} stroke="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Tested_Messages_Links_and_Detected_Scams_per_Team_Member;
