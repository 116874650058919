import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../api.config";
import { Table, Form } from "react-bootstrap";
import { toast } from "react-toastify"; // Import toast
import { listTeamMembers } from "../../utils/api";

export default function SettingBody() {
  const organizationId = localStorage.getItem("organization_id");
  const UserId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");

  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleStates, setToggleStates] = useState({});

  useEffect(() => {
    fetchTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await listTeamMembers();

      // const filteredMembers = response.filter(
      //   (member) => member._id !== UserId
      // );
      const filteredMembers = response.data.teamMembers;
      setTeamMembers(filteredMembers);
      fetchToggleStates(filteredMembers);

    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  const fetchToggleStates = async (teamMembers) => {
    try {
      const states = {};
      console.log(teamMembers);
      await Promise.all(
        teamMembers.map(async (member) => {
          const response = await axios.post(
            `${API_BASE_URL}/team/check-toggle-state`,
            // @ts-ignore
            { UserId, memberId: member._id, organizationId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              organizationId: localStorage.getItem("organization_id"),

            }
          );
          // @ts-ignore
          states[member._id] = response.data.toggleState;
        })
      );
      setToggleStates(states);
    } catch (error) {
      alert("cant get toggle");
      console.error("Error fetching toggle states:", error);
    }
  };

  const handleToggleChange = async (memberId, ToggleState) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${API_BASE_URL}/team/shared-all-urls`,
        {
          UserId,
          memberId,
          organizationId,
          ToggleState,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setToggleStates((prevStates) => ({
        ...prevStates,
        [memberId]: ToggleState,
      }));
      toast.success(response.data.message);
      setLoading(false);
    } catch (error) {
      console.error("Error updating URL sharing:", error);
      toast.error("Error updating URL sharing.");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mainSettingBody">
        <div className="settingHeading">
          <h1 style={{ fontSize: "3rem", position: "relative", left: "4rem" }}>
            Settings
          </h1>
        </div>

        <div className="settingHeading">
          <h1
            style={{ fontSize: "1.5rem", position: "relative", left: "4rem" }}
          >
            Workspace Details
          </h1>
          <p style={{ fontSize: "1.5rem", position: "relative", left: "4rem" }}>
            Organization ID: {organizationId}
          </p>
        </div>
        <div className="settingHeading">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                position: "relative",
                left: "4rem",
                color: "grey",
              }}
            >
              Sharing
            </h2>
            <button className="settingButton">Team</button>
          </div>

          <Table
            style={{
              marginTop: "2rem",
            }}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Share My Entire Chat History</th>
              </tr>
            </thead>
            <tbody>
              {teamMembers.map((member) => (
                // @ts-ignore
                <tr key={member._id}>
                  <td>
                    {
                      // @ts-ignore
                      member.FullName
                    }
                  </td>
                  <td>
                    {
                      // @ts-ignore
                      member.email
                    }
                  </td>
                  <td>
                    {
                      // @ts-ignore
                      member.role
                    }
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      label=""
                      // @ts-ignore
                      checked={toggleStates[member._id] || false}
                      disabled={loading}
                      onChange={(e) =>
                        // @ts-ignore
                        handleToggleChange(member._id, e.target.checked)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
