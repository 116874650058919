import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../api.config";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LOGO from "../../assets/Favicon/64x64.svg";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Regular expression for validating an email
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/forgot-password`,
        { email },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        toast.success("Password reset link has been sent to your email.");
        setMessage("Password reset link has been sent to your email.");
        setErrorMessage("");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
      setMessage("");
    }
  };

  const BackToLoginNavigate = () => {
    navigate("/login");
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div
        className="card p-4 shadow-lg"
        style={{ maxWidth: "400px", width: "100%", backgroundColor: "#282c34" }}
      >
        <div className="card-body">
          <img src={LOGO} alt="Logo" className="mx-auto d-block mb-3" />
          <h1 className="card-title text-center mb-3">Forgot Password</h1>
          <form onSubmit={handleForgotPassword}>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <p className="ForgotPasswordNavigate" onClick={BackToLoginNavigate}>
              Back to Login?
            </p>
            <button
              style={{ color: "#333" }}
              type="submit"
              className="login-button"
            >
              Send Reset Link
            </button>
            {message && (
              <span className="text-success d-block mt-3">{message}</span>
            )}
            {errorMessage && (
              <span
                style={{ textAlign: "center" }}
                className="text-danger d-block mt-3"
              >
                {errorMessage}
              </span>
            )}
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordPage;
