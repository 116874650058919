import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchSeats, listTeamMembers, listUrls } from "./api";
import axios from "axios";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [listMembers, setListMembers] = useState([]); //list-team-members
  const [seats, setSeats] = useState(null); //list-team-members
  const [region, setRegion] = useState(""); //list-team-members
  const [availableSeats, setAvailableSeats] = useState(null); //list-team-members
  const [urls, setUrls] = useState([]); //list-team-members
  const plan = localStorage.getItem("plan");
  const max_team_members = localStorage.getItem("max_team_members");

  console.log("availableSeats", availableSeats);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listTeamMembers();
        let seats = await fetchSeats();
        let urls = await listUrls(localStorage.getItem("organization_id"));
        setListMembers(response);
        setSeats(seats.data.seats);

        setAvailableSeats(
          seats.data.seats -
            response.data.pendingInvites.length -
            response.data.teamMembers.length -
            1
        );
        setUrls(urls);
      } catch (error) {
        console.log(error);
      }
    };
    if (plan === "plus") {
      return;
    }

    fetchData();
  }, [plan, max_team_members]);

  const fetchRegion = async (ipAddress) => {
    if (!ipAddress) return; // Don't fetch i

    if (localStorage.getItem("region")) {
      setRegion(localStorage.getItem("region"));
      return;
    }

    try {
      const response = await axios.get(
        `https://ipapi.co/${ipAddress}/country/`
      );

      if (response.status === 200) {
        localStorage.setItem("region", response.data);
        setRegion(response.data);
      } else {
        setRegion("US");
      }
    } catch (error) {
      setRegion("PK");
      console.error("Error fetching region:", error);
    } finally {
    }
  };

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = response.data.ip;
      await fetchRegion(ipAddress);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchIpAddress();
  }, []);

  const updateSeats = async () => {
    try {
      let seatsResponse = await fetchSeats();
      const response = await listTeamMembers();
      setListMembers(response);
      setSeats(seatsResponse.data.seats); // Update only the seats
      setAvailableSeats(
        seats.data.seats -
          response.data.pendingInvites.length -
          response.data.teamMembers.length -
          1
      );
    } catch (error) {
      console.log("Failed to update seats:", error);
    }
  };

  return (
    <DataContext.Provider
      value={{ listMembers, seats, availableSeats, urls, updateSeats, region }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
