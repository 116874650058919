import React, { useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Table,
  Tabs,
  Tab,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../../api.config";
import TeamPaymentBox from "../../pages/upgradePlan/team.payment";
import { fetchSeats, localize, refresh } from "../../utils/api";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { BsXCircle } from "react-icons/bs";
import { DataProvider, useData } from "../../utils/DataContext";
import PricingBox from "../PricingBox";

export default function AddTeamMembers() {
  const token = localStorage.getItem("token");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamAdmin, setTeamAdmin] = useState({});
  const [pendingInvites, setPendingInvites] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { listMembers, seats, availableSeats, updateSeats } = useData();
  const [remainingSeats, setRemainingSeats] = useState(0);
  const hasTeamPlan = localStorage.getItem("plan") === "team" ? true : false;

  //NOTE: used for add-team-protection only
  const [viewPaymentBox, setViewPaymentBox] = useState("");
  const [isBoxOpen_delete, setIsBoxOpen_delete] = useState(null);

  //NOTE: Notifies seat limit and shows Buy More Seats link (=> plan=team)
  const [isSeatLimitReached, setIsSeatLimitReached] = useState(false);

  //NOTE Shows Upgrade to Teams Link to an individual (=> plan=individual)
  // const [isTeamBlocked, setIsTeamBlocked] = useState(false);

  //NOTE: Shows Invitation Dialog Box
  const [isBoxOpen_invitation, setIsBoxOpen_invitation] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const fetchTeamMembers = async () => {
    setLoading(true);
    try {
      console.log(listMembers);
      await setTeamMembers([
        ...listMembers?.data?.teamMembers,
        listMembers?.data?.admin,
      ]);
      await setFilteredMembers([
        ...listMembers?.data?.teamMembers,
        // listMembers?.data?.admin,
      ]);
      await setTeamAdmin(listMembers?.data?.admin);
      console.log(listMembers?.data?.admin);
      await setPendingInvites(listMembers?.data?.pendingInvites);
    } catch (error) {
      console.error("Error fetching team members:", error);
    } finally {
      setLoading(false);
      await setRemainingSeats(
        seats?.data?.seats -
          listMembers?.data?.teamMembers?.length -
          listMembers?.data?.pendingInvites?.length -
          1
      );
      if (remainingSeats <= 0) {
        setIsSeatLimitReached(true);
      } else {
        setIsSeatLimitReached(false);
      }
    }
  };
  useEffect(() => {
    console.log("ls2", location.state);
    const refreshpage = async (type) => {
      try {
        const response = await refresh(localStorage.getItem("email"));
        const message = await localize(response);
        if (type === "add-seats" && message.message === "success") {
          setIsSeatLimitReached(false);
          setIsBoxOpen_invitation(true);
          setSearchParams("");
          setRemainingSeats(localStorage.getItem("max_team_members"));
          setViewPaymentBox("");
          await updateSeats();
        }
      } catch (error) {
        console.log(error);
      }
    };

    //NOTE: Shows Invitation Dialog Box on URLParam Payment === true
    if (searchParams.get("add-seats") === "true") {
      refreshpage("add-seats");
      return;
    }
    if (
      location.state?.state === "add-team-protection" ||
      searchParams.get("add-team-protection") === "true"
    ) {
      setViewPaymentBox("add-team-protection");
      return;
      // setIsTeamBlocked(true);
    } else if (location.state?.state === "add-seats") {
      setViewPaymentBox("add-seats");
      return;
    }
    // if (location.state === "add-protection") {
    // }

    // fetchSeats().then((res) => {
    // });

    // if (
    //   localStorage.getItem("plan") === "team" &&
    //   localStorage.getItem("role") === "admin"
    // ) {
    //   setIsBlocked(false);
    //   setViewPaymentBox(false);
    // }
    // if (isBlocked) {
    //   return;
    // }

    fetchTeamMembers();
  }, [location, searchParams, seats]);

  const handleShow = () => {
    const notifyLimit = () =>
      toast.error(`Your limit to add members has reached!`);
    const notifyUpgrade = () =>
      toast.error(
        `You need to upgrade your account to Teams to use this feature!`
      );

    if (location.state?.state === "add-team-protection") {
      notifyUpgrade();
      return;
    }
    if (availableSeats <= 0) {
      notifyLimit();
      return;
    }
    setIsBoxOpen_invitation(true);
    setEmail("");
    setSuccess("");
    setError("");
  };
  const handleClose = () => {
    setIsBoxOpen_invitation(false);
    setEmail("");
    setSuccess("");
    setError("");
  };
  const onCloseDeleteModal = () => {
    setIsBoxOpen_delete(false);
  };
  const handleInvite = async () => {
    setError("");
    setSuccess("");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/team/add-team-member`,
        { email, organizationId: localStorage.getItem("organization_id") },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Invitation sent successfully") {
        setSuccess(response.data.message);
        setError("");
        // @ts-ignore
        setPendingInvites((prev) => [...prev, { email, role: "member" }]);

        setEmail("");
        window.location.reload();
      } else {
        console.log(response.data);
        setError(response.data.error);
      }
    } catch (error) {
      console.log(error);
      setError(error.response?.data.error);
      setSuccess("");
    }
  };

  const handleDelete = async (memberId) => {
    console.log(memberId);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/team/delete-team-member`,
        { memberId, organizationId: localStorage.getItem("organization_id") },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Team member deleted successfully") {
        setTeamMembers((prev) =>
          // @ts-ignore
          prev.filter((member) => member._id !== memberId)
        );
        setFilteredMembers((prev) =>
          // @ts-ignore
          prev.filter((member) => member._id !== memberId)
        );
        setPendingInvites((prev) =>
          // @ts-ignore
          prev.filter((member) => member._id !== memberId)
        );
      }
      setIsBoxOpen_delete(null);
    } catch (error) {
      console.error("Error deleting team member:", error);
      setIsBoxOpen_delete(null);
    } finally {
      window.location.reload();
    }
  };
  const handleInviteDelete = async (inviteId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/team/delete-invite`,
        { inviteId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        response.status === 200 &&
        response.data.message === "Invite deleted successfully"
      ) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = teamMembers.filter(
      (member) =>
        // @ts-ignore
        member.FullName.toLowerCase().includes(query) ||
        // @ts-ignore
        member.email.toLowerCase().includes(query)
    );
    // @ts-ignore
    setFilteredMembers(filtered);
  };

  console.log("filtered members: ", filteredMembers);

  return (
    <div className="h-100 position-relative" style={{ width: "85%" }}>
      {(viewPaymentBox === "add-team-protection" ||
        viewPaymentBox === "add-seats") && (
        <div
          className="h-100 d-flex flex-row justify-content-center align-items-center"
          style={{
            position: "absolute",
            backdropFilter: "blur(8px)",
            zIndex: "1",
            width: "100%",
          }}
        >
          <div className="w-100 d-flex justify-content-center">
            <DataProvider>
              <TeamPaymentBox></TeamPaymentBox>
            </DataProvider>
            <BsXCircle
              onClick={() => {
                setViewPaymentBox(false);
              }}
              style={{
                fontSize: "x-large",
                color: "black",
                alignSelf: "flex-start",
              }}
            ></BsXCircle>
          </div>
        </div>
      )}
      {/* {viewPaymentBox === "add-protection" && (
        <div
          className="w-100 h-100"
          style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <div
            className="h-100 w-100 d-flex flex-row justify-content-center align-items-center"
            style={{
              position: "absolute",
              backdropFilter: "blur(8px)",
              zIndex: "1",
              width: "10",
            }}
          ></div>
          <UpgradePlan />
        </div>
      )} */}
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
          padding: "2rem",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h1
              style={{
                color: "#000",
              }}
            >
              Team Members
            </h1>
            <p className="text-muted">
              {`Team - ${teamMembers.length} members | Remaining - ${
                !isNaN(availableSeats) ? availableSeats : 0
              } seats`}
            </p>
          </div>

          <div className="search-bar">
            <div>
              <Link
                className="px-2 mx-2"
                onClick={() => setViewPaymentBox("add-seats")}
                hidden={
                  availableSeats >= 1 || localStorage.getItem("plan") === "plus"
                }
                state={{ state: "add-seats" }}
              >
                Buy More Seats
              </Link>
              <Link
                className="px-2 mx-2"
                onClick={() => setViewPaymentBox("add-team-protection")}
                state={{ state: "add-team-protection" }}
                hidden={hasTeamPlan}
                replace={true}
              >
                Upgrade to Teams
              </Link>
              <Button variant="success" onClick={handleShow}>
                Invite member
              </Button>
            </div>
          </div>
        </div>

        <Tabs defaultActiveKey="all-members" id="members-tab">
          <Tab
            eventKey="all-members"
            title={<span style={{ color: "darkgrey" }}>All members</span>}
          >
            <div
              className="mt-3"
              style={{
                overflow: "auto",
                height: "70vh",
              }}
            >
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </InputGroup>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    // @ts-ignore
                    size="md"
                    style={{
                      // backgroundColor:"red",
                      color: "grey",
                    }}
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Delete member</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredMembers?.map((member) => (
                      // @ts-ignore
                      <tr key={member._id}>
                        <td>
                          {
                            // @ts-ignore
                            member.FullName
                          }
                        </td>
                        <td>
                          {
                            // @ts-ignore
                            member.email
                          }
                        </td>
                        <td>
                          {
                            // @ts-ignore
                            "member"
                          }
                        </td>
                        <td
                          style={{
                            cursor:
                              // @ts-ignore
                              member.role === "admin"
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          <Button
                            className="btn btn-danger"
                            // @ts-ignore
                            onClick={() => setIsBoxOpen_delete(member)}
                            // @ts-ignore
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {teamAdmin._id && (
                      <tr key={teamAdmin._id}>
                        <td>
                          {
                            // @ts-ignore
                            teamAdmin.FullName
                          }
                        </td>
                        <td>
                          {
                            // @ts-ignore
                            teamAdmin.email
                          }
                        </td>
                        <td>
                          {
                            // @ts-ignore
                            teamAdmin.role
                          }
                        </td>
                        <td
                          style={{
                            cursor:
                              // @ts-ignore
                              teamAdmin.role === "admin"
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          <Button
                            className="btn btn-danger"
                            // @ts-ignore
                            onClick={() => setIsBoxOpen_delete(teamAdmin)}
                            // @ts-ignore
                            disabled={teamAdmin.role === "admin"}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </Tab>

          <Tab
            title={<span style={{ color: "darkgrey" }}>Pending invites</span>}
            eventKey="pending-invites"
          >
            {pendingInvites.length === 0 ? (
              <h1
                style={{
                  color: "#000",
                  textAlign: "center",
                  marginTop: "3rem",
                }}
              >
                No pending invites
              </h1>
            ) : (
              <Table hover>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Delete Invite</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingInvites?.map((invite) => (
                    <tr
                      key={
                        // @ts-ignore
                        invite._id
                      }
                    >
                      <td>
                        {
                          // @ts-ignore
                          invite.email
                        }
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        <Button
                          className="btn btn-danger"
                          // @ts-ignore
                          onClick={() => {
                            console.log(invite);
                            setIsBoxOpen_delete(invite);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Tab>
        </Tabs>
        {/* NOTE: Delete user modal */}

        <Modal show={isBoxOpen_delete} onHide={onCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete?</Modal.Title>
          </Modal.Header>

          {isBoxOpen_delete?.role ? (
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
              <p style={{ color: "black" }}>Are you sure you want to delete:</p>
              <div>
                <p style={{ color: "black" }}>
                  Email:{" "}
                  <span className="text-danger">{isBoxOpen_delete?.email}</span>
                </p>
              </div>
            </Modal.Body>
          ) : isBoxOpen_delete?.inviteTokenExpiration ? (
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
              <p>Are you sure you want to delete invite:</p>
              <div>
                <p>
                  Name:
                  <span className="text-danger">{isBoxOpen_delete?.email}</span>
                </p>
              </div>
            </Modal.Body>
          ) : (
            <p>No information available for deletion.</p>
          )}
          <Modal.Footer className="justify-content-between">
            <p
              className="float-start"
              style={{ color: "black", fontSize: "20px" }}
            >
              <span style={{ color: "#818181" }}>
                By doing this you will get 1 seat for a new member
              </span>
            </p>
            <div className="">
              <Button
                className="mx-1"
                variant="secondary"
                onClick={onCloseDeleteModal}
              >
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  console.log(isBoxOpen_delete);
                  return isBoxOpen_delete?.role
                    ? handleDelete(isBoxOpen_delete?._id)
                    : isBoxOpen_delete?.inviteTokenExpiration
                    ? handleInviteDelete(isBoxOpen_delete?._id)
                    : null;
                }}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* NOTE: Invite Modal */}
        <Modal show={isBoxOpen_invitation} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Invite Member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}
            </Form>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <p
              className="float-start"
              style={{ color: "black", fontSize: "20px" }}
            >
              <span style={{ color: "#818181" }}>
                You are using 1 of your remaining {availableSeats} seat(s)
              </span>
            </p>
            <div className="">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleInvite}>
                Invite
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
