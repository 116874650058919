import axios from "axios";
import React, { PureComponent, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { API_BASE_URL } from "../../../api.config";

const Cumulative_Cost_Savings_Over_Time = ({ year }) => {
  const token = localStorage.getItem("token");
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const data = async () => {
      const data = await axios.get(
        `${API_BASE_URL}/graph/cumulative_cost_savings_over_time`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            year: year,
          },
        }
      );
      console.log("graphing", data.data);
      setGraphData(data.data);
    };
    data();
  }, []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis
          tickFormatter={(item, ind) => {
            if (Math.abs(item) >= 1000000) {
              return `${(item / 1000000).toFixed(1)}M`;
            } else if (item >= 1000) {
              return `${(item / 1000).toFixed(1)}k`;
            } else {
              return item;
            }
          }}
        >
          <Label
            position={"insideLeft"}
            value="USD (million)"
            angle={-90}
          ></Label>
        </YAxis>
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="cost"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Cumulative_Cost_Savings_Over_Time;
