import React, { useEffect, useState } from "react";
import NavContent from "../../components/NavContent";

import AddTeamMembers from "../../components/AddTeamMembers/AddTeamMembers";
import { listUrls } from "../../utils/api";
import { useNavigate } from "react-router-dom";

export default function AddTeamWorkSpace() {
  const [UserData, setUserData] = useState([]);
  const [chatLog, setChatLog] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const plan = localStorage.getItem("plan");
  const navigate = useNavigate();
  const fetchUserData = async () => {
    setShowMenu(true);
    try {
      const userId = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");

      if (userId && token) {
        const response = await listUrls(userId);

        if (response) {
          const data = await response.data;
          console.log(data);
          setUserData(data);
        } else {
          console.error("Failed to fetch user data");
        }
      } else {
        console.error("User ID or token not found in localStorage");
      }
    } catch (error) {
      console.error("An error occurred while fetching user data:", error);
    }
  };

  useEffect(() => {
    // if (plan !== "team") {
    //   navigate("/dashboard");
    //   return;
    // }
    fetchUserData();
  }, []);
  // console.log(chatLog, "chatLog");
  return (
    <>
      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={UserData}
              setChatLog={setUserData}
              setShowMenu={showMenu}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}
      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={UserData}
              setChatLog={setChatLog}
              setShowMenu={setShowMenu}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent
          chatLog={UserData}
          setChatLog={setUserData}
          setShowMenu={undefined}
        />
      </aside>

      {/* side nav closed */}

      {/* main body start */}
      <AddTeamMembers />
    </>
  );
}
