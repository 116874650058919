import './normal.css'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import './normal.css'
import './App.css'
import Home from './pages/home/Home'
import Login from './pages/Login/Login'
import LoginForm from './components/login/LoginForm'
import SignUpFormIndividual from './pages/signup/SignUpFormIndividual'
import SignUpFormTeam from './pages/signup/SignUpFormTeam'
import Setting from './pages/settings/Setting'
import { Navigate, Route, Routes } from 'react-router-dom'
import RequireAuth, {
  DisableOnLogin,
  MobileOnly,
  ProtectedRoute,
} from './RequireAuth'
import NotFound from './pages/NotFound'
import UpgradePlan from './pages/upgradePlan/UpgradePlan'
import AddTeamWorkSpace from './pages/AddTeamWorkSpace/AddTeamWorkSpace'
import AcceptInvitation from './pages/AcceptInvitation/AcceptInvitation'
import CompleteRegistration from './pages/AcceptInvitation/CompleteRegistration'
import 'bootstrap/dist/css/bootstrap.min.css'
import UrlHistory from './pages/UrlHistory/UrlHistory'
import Analytics from './pages/Analytics/Analytics'
import BillingPage from './pages/BillingPage/BillingPage'
import PaymentSuccess from './pages/StripePayment/PaymentSuccess'
import PaymentFailed from './pages/StripePayment/PaymentFailed'
import ForgotPasswordForm from './components/ForgotPassword/ForgotPass'
import ResetPasswordPage from './components/ResetPassword/ResetPassword'
import SelectOrganization from './pages/select-organization'
import Dummy from './pages/dummy/dummy'
import { DataProvider } from './utils/DataContext'
import 'react-datepicker/dist/react-datepicker.css'
// import 'react-datepicker/dist/react-datepicker-cssmodules./css'

function App() {
  return (
    <div className='App'>
      {/* <RequireAuth /> */}
      <Routes>
        <Route
          path='/'
          element={
            <DisableOnLogin>
              <Login />
            </DisableOnLogin>
          }
        />
        <Route
          path='/login'
          element={
            <DisableOnLogin>
              <LoginForm />
            </DisableOnLogin>
          }
        />
        <Route
          path='/signup-individual'
          element={
            <DisableOnLogin>
              <SignUpFormIndividual />
            </DisableOnLogin>
          }
        />
        <Route
          path='/signup-team'
          element={
            <DisableOnLogin>
              <SignUpFormTeam />
            </DisableOnLogin>
          }
        />
        <Route
          path='/upgrade-plan'
          element={
            <DisableOnLogin>
              <UpgradePlan />
            </DisableOnLogin>
          }
        />
        <Route
          path='/select-organization'
          element={
            <MobileOnly>
              <ProtectedRoute>
                <DataProvider>
                  <SelectOrganization />
                </DataProvider>
              </ProtectedRoute>
            </MobileOnly>
          }
        />
        <Route
          path='/dashboard'
          element={
            <MobileOnly>
              <ProtectedRoute>
                <DataProvider>
                  <Home />
                </DataProvider>
              </ProtectedRoute>
            </MobileOnly>
          }
        />
        <Route
          path='/url-history/:id'
          element={
            <MobileOnly>
              <ProtectedRoute>
                <DataProvider>
                  <UrlHistory />
                </DataProvider>
              </ProtectedRoute>
            </MobileOnly>
          }
        />
        <Route
          path='/add-team-workspace'
          element={
            <MobileOnly>
              <ProtectedRoute>
                <DataProvider>
                  <AddTeamWorkSpace />
                </DataProvider>
              </ProtectedRoute>
            </MobileOnly>
          }
        />
        <Route
          path='/analytics'
          element={
            <MobileOnly>
              <ProtectedRoute>
                <DataProvider>
                  <Analytics />
                </DataProvider>
              </ProtectedRoute>
            </MobileOnly>
          }
        />
        <Route
          path='/setting'
          element={
            <ProtectedRoute>
              <Setting />
            </ProtectedRoute>
          }
        />
        <Route
          path='/accept-invitation'
          element={
            // <ProtectedRoute>
            <AcceptInvitation />
            // </ProtectedRoute>
          }
        />
        <Route
          path='/complete-registration'
          element={<CompleteRegistration />}
        />
        <Route
          path='/billing'
          element={
            <MobileOnly>
              <ProtectedRoute>
                <DataProvider>
                  <BillingPage />
                </DataProvider>
              </ProtectedRoute>
            </MobileOnly>
          }
        />
        <Route
          path='/payment-success'
          element={
            // <DisableOnLogin>
            <PaymentSuccess />
            // </DisableOnLogin>
          }
        />
        <Route path='/payment-failed' element={<PaymentFailed />} />
        <Route
          path='/forgot-password'
          element={
            <DisableOnLogin>
              <ForgotPasswordForm />
            </DisableOnLogin>
          }
        />
        <Route path='/reset-password/:token' element={<ResetPasswordPage />} />
        {/* Catch-all route for 404 page */}
        <Route path='*' element={<Navigate to={'/dashboard'} />} />
        <Route path='/dummy' element={<Dummy />} />
      </Routes>
    </div>
  )
}

export default App
