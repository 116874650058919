import React from 'react'
import { Modal, Button, ListGroup, Form } from 'react-bootstrap'

const ShareWithTeamModal = ({
  show,
  handleClose,
  filteredMembers,
  toggleShare,
  originalUrl,
  shareStatus,
}) => {
  const truncateText = (text, charLimit) => {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...'
    }
    return text
  }
  // console.log("shareStatus", shareStatus);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share with Team Members</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4
          style={{
            marginBottom: '1rem',
            width: '100%',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          chat: {truncateText(originalUrl, 10)}
        </h4>
        <div
          style={{
            width: '100%',
            border: '1px solid #DEE2E6',
            height: '1px',
            marginBottom: '1rem',
          }}
        ></div>

        {filteredMembers?.length === 0 ? (
          <h4 className='text-center'>No member in Team</h4>
        ) : (
          <ListGroup>
            {filteredMembers?.map((member, index) => (
              <ListGroup.Item
                key={member?._id}
                className='d-flex justify-content-between align-items-center'
              >
                {member.FullName}
                <Form.Check
                  type='switch'
                  id={`share-switch-${index}`}
                  label=''
                  checked={shareStatus.includes(member?._id)}
                  onChange={() => {
                    toggleShare(member?._id)
                  }}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShareWithTeamModal
