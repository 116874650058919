import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Container, Table } from 'react-bootstrap'
import NavContent from '../../components/NavContent'
import { API_BASE_URL } from '../../api.config'
import {
  createCustomerPortalSession,
  getSubscriptionHistory,
} from '../../utils/api'
import { Link, useNavigate } from 'react-router-dom'

const BillingPage = () => {
  const [transactions, setTransactions] = useState([])
  const navigate = useNavigate()
  const [plan, setPlan] = useState('plus')

  useEffect(() => {
    setPlan(localStorage.getItem('plan'))
  }, [])

  useEffect(() => {
    const fetchTransactions = async () => {
      const response = await getSubscriptionHistory()
      setTransactions(response.data)
      console.log(response.data)
    }

    fetchTransactions()
  }, [])

  const navigateToCustomerPortal = async (customer_id) => {
    console.log(customer_id)
    try {
      const item = await createCustomerPortalSession(customer_id)
      window.open(item.data.url)
    } catch (error) { }
  }

  return (
    <>
      <nav>
        <div className='navItems'>
          <NavContent />
        </div>
        <div className='navCloseIcon'>
          <svg
            fill='#fff'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 100 100'
            xmlSpace='preserve'
            stroke='#fff'
            width={42}
            height={42}
          >
            <path d='m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z' />
          </svg>
        </div>
      </nav>

      <Container className='mt-4'>
        <h1>Payment History</h1>
        {/* {plan === 'team' && (
          <a
            href={
              'https://docs.stripe.com/billing/subscriptions/prorations#how-prorations-work'
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            How Our Billing Works
          </a>
        )} */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Subscription Package</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={transaction.paymentId}>
                <td>{index + 1}</td>
                <td>
                  {new Date(transaction.created * 1000).toLocaleDateString()}
                </td>
                <td>{transaction.package}</td>
                <td>{transaction.amount}</td>
                <td
                  style={{
                    color: transaction.status === 'active' ? 'green' : 'red',
                  }}
                >
                  {transaction.status}
                </td>
                <td>
                  <Button
                    className='btn btn-blue'
                    onClick={() =>
                      navigateToCustomerPortal(transaction.customerId)
                    }
                  >
                    Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

export default BillingPage
