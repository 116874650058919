import axios from "axios";
import React, { forwardRef, PureComponent, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { API_BASE_URL } from "../../../api.config";

const Cost_Savings_and_ROI_Correlation = ({ year }) => {
  const token = localStorage.getItem("token");
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const data = async () => {
      const data = await axios.get(
        `${API_BASE_URL}/graph/cost_savings_and_roi_correlation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            year: year,
          },
        }
      );
      console.log("graphing", data.data);
      setGraphData(data.data);
    };
    data();
  }, []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={400}
        data={graphData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month">
          <Label position={"end"} value="Months" dy={20}></Label>
        </XAxis>
        {/* <XAxis dataKey="month" /> */}
        <YAxis
          yAxisId="left"
          orientation="left"
          stroke="#8884d8"
          tickFormatter={(item, ind) => {
            if (Math.abs(item) >= 1000000) {
              return `${(item / 1000000).toFixed(1)}M`;
            } else if (item >= 1000) {
              return `${(item / 1000).toFixed(1)}k`;
            } else {
              return item;
            }
          }}
        >
          <Label
            position={"insideLeft"}
            value="Cost Savings (SM)"
            angle={-90}
            style={{
              textAnchor: "middle",
              fontSize: "80%",
              fill: "#8884d8",
            }}
          ></Label>
        </YAxis>
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#82ca9d"
          tickFormatter={(item, ind) => {
            if (Math.abs(item) >= 1000000) {
              return `${(item / 1000000).toFixed(1)}M`;
            } else if (item >= 1000) {
              return `${(item / 1000).toFixed(1)}k`;
            } else {
              return item;
            }
          }}
        >
          <Label
            position={"insideRight"}
            value="ROI (%)"
            angle={-90}
            // stroke="#82ca9d"
            style={{
              textAnchor: "middle",
              fontSize: "80%",
              fill: "#82ca9d",
            }}
          ></Label>
        </YAxis>
        <Tooltip formatter={(value) => value.toFixed(1)} />
        <Legend verticalAlign="top" height={36} />
        <Line
          name="Cost Saving"
          yAxisId="left"
          type="monotone"
          dataKey="cost"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          name="ROI %"
          yAxisId="right"
          type="monotone"
          dataKey="roi"
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default Cost_Savings_and_ROI_Correlation;
