import React from "react";
import { createContext, useState } from "react";

export const Context = createContext(null);

export const ContextProvider = (props) => {
  const [APICALL, setAPICALL] = useState();
  const [chatLog, setChatLog] = useState([]);
  // console.log(chatLog);
  return (
    <>
      <Context.Provider value={{ APICALL, setAPICALL, setChatLog, chatLog }}>
        {props.children}
      </Context.Provider>
    </>
  );
};
