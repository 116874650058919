import React, { useState } from "react";
import axios from "axios";
import "../../pages/signup/signupform.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api.config";
import LOGO from "../../assets/Favicon/64x64.svg";
import { localize } from "../../utils/api";

const SignupForm = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoginPassword, setShowLoginPassword] = useState(true);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/login`,
        {
          email: loginEmail,
          password: loginPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        console.log("Login successful");
        await localize(response);
        console.log(response.data);
        if (
          response.data.organizationId.length > 1 &&
          response.data.plan === "team"
        ) {
          navigate("/select-organization");
        } else if (
          response.data.organizationId.length > 0 &&
          response.data.plan === "plus"
        ) {
          navigate("/select-organization");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      console.log(errorMessage);
      console.log(error);
      setErrorMessage(errorMessage);
    }
  };

  const ForgotPasswordNavigate = async () => {
    navigate("/forgot-password");
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div
        className="card p-4 shadow-lg"
        style={{ maxWidth: "400px", width: "100%", backgroundColor: "#282c34" }}
      >
        <div className="card-body">
          <img src={LOGO} alt="" className="mx-auto d-block mb-3" />
          <h1 className="card-title text-center mb-3">Welcome Back</h1>
          <form
            style={{
              width: "100%",
            }}
            onSubmit={handleLogin}
          >
            <div className="mb-3">
              <input
                type="email"
                name="loginEmail"
                id="loginEmail"
                className="form-control"
                placeholder="Enter email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value.toLowerCase())}
                required
              />
            </div>
            <div className="mb-3 position-relative">
              <input
                type={showLoginPassword ? "password" : "text"}
                name="loginPassword"
                id="loginPassword"
                className="form-control"
                placeholder="Enter password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                required
                autoComplete="password"
              />
              <i
                onClick={() => setShowLoginPassword(!showLoginPassword)}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
              >
                {!showLoginPassword ? (
                  <svg
                    width={26}
                    height={26}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      stroke="#202123"
                      strokeWidth={0.792}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7Z" />
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </g>
                    <title>Show Password</title>
                  </svg>
                ) : (
                  <svg
                    width={26}
                    height={26}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#000"
                  >
                    <path
                      d="M20 14.834C21.308 13.332 22 12 22 12s-3.636-7-10-7a8.595 8.595 0 0 0-2 .236M12 9a2.995 2.995 0 0 1 3 3M3 3l18 18m-9-6a2.997 2.997 0 0 1-2.959-2.5M4.147 9c-.308.345-.585.682-.828 1C2.453 11.128 2 12 2 12s3.636 7 10 7c.341 0 .675-.02 1-.058"
                      stroke="#202123"
                      strokeWidth={0.768}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <title>Hide Password</title>
                  </svg>
                )}
              </i>
            </div>

            <p
              className="ForgotPasswordNavigate"
              style={{ marginBottom: "5px", textDecoration: "underline" }}
              onClick={ForgotPasswordNavigate}
            >
              Forgot your password?
            </p>
            <button
              style={{
                color: "#333",
              }}
              type="submit"
              className="login-button"
            >
              Log in
            </button>
            {errorMessage && (
              <span style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <span
                  className="signupFormContainer span"
                  style={{ width: "auto" }}
                >
                  {errorMessage}
                </span>
                <a
                  style={{ textDecoration: "underline", color: "#bbff4b" }}
                  href="/login"
                >
                  Login
                </a>
              </span>
            )}

            <p
              className="ForgotPasswordNavigate"
              onClick={() => navigate("/upgrade-plan")}
              style={{ textDecoration: "underline", marginTop: 10 }}
            >
              Sign up if you're a new user
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
