import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createCheckoutSessionForInd } from "../../utils/api";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLICABLE_KEY } from "../../api.config";
import { Spinner } from "react-bootstrap";
import { useData } from "../../utils/DataContext";

const IndividualPaymentBox = () => {
  const { region } = useData(); // Initialize with null
  const [billingIndividual, setBillingIndividual] = useState("month");
  // const [region, setRegion] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const individualPricePerPerson = region === "GB" ? 11.99 : 15.99;
  const totalIndividualPrice = individualPricePerPerson;

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user's region based on IP address
    // axios
    //   .get("https://api.ipify.org?format=json")
    //   .then((response) => {
    //     const ipAddress = response.data.ip;
    //     axios
    //       .get(`https://ipapi.co/${ipAddress}/country/`)
    //       .then((response) => {
    //         const userRegion = response.data;
    //         // @ts-ignore
    //         setRegion(userRegion);
    //         console.log("Region:", userRegion);
    //         setLoading(false);
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching region:", error);
    //         setLoading(false);
    //       });
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching IP address:", error);
    //     setLoading(false);
    //   });
    // const fetchRegion = async (ipAddress) => {
    //   if (!ipAddress) return; // Don't fetch if ipAddress is not available
    //   try {
    //     const response = await axios.get(
    //       `https://ipapi.co/${ipAddress}/country/`
    //     );
    //     setRegion(response.data);
    //   } catch (error) {
    //     console.error("Error fetching region:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // const fetchIpAddress = async () => {
    //   try {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     const ipAddress = response.data.ip;
    //     await fetchRegion(ipAddress);
    //   } catch (error) {
    //     console.error("Error fetching IP address:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // fetchIpAddress();
  }, []);

  const handlePlanSelection = async (plan, price, billingOption) => {
    const packageDetails = {
      plan,
      price,
      billingOption,
      region,
    };
    console.log(packageDetails);
    if (localStorage.getItem("plan") === "none") {
      const session = await createCheckoutSessionForInd(
        {
          region,
          customer_email: localStorage.getItem("email"),
        },
        "add-individual-protection"
      );
      if (session.status === 200) {
        console.log("Stripe API Response:", session.data.sessionId);
        const { sessionId } = session.data;
        const stripe = await loadStripe(`${STRIPE_PUBLICABLE_KEY}`);
        const { error } = (await stripe?.redirectToCheckout({
          sessionId,
        })) ?? { error: "not working" };
        if (error) {
          alert("error occured during payment");
          return;
        }
      }

      return;
    }

    navigate("/signup-individual", {
      state: { packageDetails: { ...packageDetails, method: "create" } },
    });
  };

  return (
    <div id="main-plus-payment-card" className="col-lg-6">
      <div className="card upgrade-card d-flex flex-column justify-content-center">
        {region ? (
          <div className="card-body" style={{ minHeight: 560 }}>
            <h5 className="card-title">Plus Plan</h5>
            <h6 className="card-subtitle mb-2">
              {region !== "GB"
                ? `USD $${totalIndividualPrice.toFixed(
                    2
                  )} /${billingIndividual}`
                : `GBP £${totalIndividualPrice.toFixed(
                    2
                  )} /${billingIndividual}`}
            </h6>
            <button
              className="btn btn-primary btn-block upgrade-button"
              onClick={() =>
                handlePlanSelection(
                  "plus",
                  totalIndividualPrice,
                  billingIndividual
                )
              }
              disabled={!region}
            >
              Add individual subscription
            </button>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Benefits: </li>
              <li>
                ✔ High accuracy message and URL analysis and protection on
                social media, SMS, email, everywhere
              </li>
              <li>✔ Protection on all devices - Mobile, PC, etc.</li>
              <li>✔ Early access to new features</li>
            </ul>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndividualPaymentBox;
