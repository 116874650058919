import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Container, Spinner, Alert } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { API_BASE_URL } from "../../api.config";

const AcceptInvitation = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  // const location = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      console.log(token);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/auth/accept-invitation?token=${token}`
        );
        if (
          response.status == 200 &&
          response.data.message === "Team member created successfully"
        ) {
          // Redirect to complete registration after token verification
          console.log("first called");
          setTimeout(
            () => navigate(`/complete-registration?token=${token}`),
            4000
          );
        } else if (
          response.status === 200 &&
          response.data.message === "Team member added successfully"
        ) {
          console.log("second called");
          localStorage.clear();
          setTimeout(() => navigate(`/login`), 4000);
        }
      } catch (err) {
        setError("An error occurred while verifying the token.");
      } finally {
        setLoading(false);
      }
    };
    verifyToken();
  }, [token, navigate]);

  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container className="text-center" style={{ marginTop: "50px" }}>
      {error && <Alert variant="danger">{error}</Alert>}
      {!error && (
        <div>
          <FaCheckCircle size={100} color="green" />
          <h1>Invitation Accepted!</h1>
          <p>Your invitation has been successfully verified.</p>
        </div>
      )}
    </Container>
  );
};

export default AcceptInvitation;
