import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import MobileAppsButtons from "./components/MobileAppsButtons";

const RequireAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      return;
    } else {
      navigate("/");
    }
  }, []);

  // const restrictedRoutes = ["/analytics", "/add-team-workspace", ];
  // if (userRole === "member" && restrictedRoutes.includes(window.location.pathname)) {
  //   navigate("/404"); // Redirect to 404 page if user is a member trying to access a restricted route
  //   return null; // Return null to prevent rendering of the child component
  // }
  return null;
};
export const DisableRoute = ({ children }) => {
  const token = localStorage.getItem("token"); // Adjust this to where you store your token
  return token ? children : null;
};
export const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token"); // Adjust this to where you store your token
  // return token ? children : <Navigate to="/login" />;
  return token ? children : <Navigate to="/login" />;
};
export const DisableOnLogin = ({ children }) => {
  const token = localStorage.getItem("token"); // Adjust this to where you store your token
  return token ? <Navigate to="/dashboard" /> : children;
};

export const MobileOnly = ({ children }) => {
  const match = useMediaQuery("(min-width: 480px)");
  return match ? children : <MobileAppsButtons direction={false} />;
};

export default RequireAuth;
