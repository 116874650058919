import React from "react";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import LOGO from "../assets/Logo/Logo_hor_green.svg";

const MobileAppsButtons = ({ direction = true }) => {
  const APKUrl = "https://play.google.com/store/apps/details?id=host";
  const IOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";

  return (
    <>
      <div className="loginContainer">
        <div className="loginContainerContent">
          <div
            style={{
              marginBottom: "20px",
              height: "100px",
            }}
          >
            <img width={"100%"} style={{ padding: "20px" }} src={LOGO} alt="" />
          </div>
          <h1
            style={{
              textAlign: "center",
              lineHeight: "normal",
            }}
          >
            Welcome to VerifiedX
          </h1>
          <p
            style={{
              marginTop: "1rem",
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            Your Ultimate Scam Protection Assistant
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: direction ? "start" : "center",
              alignItems: "center",
              flexDirection: direction ? "row" : "column",
              gap: 10,
              marginBottom: direction ? 0 : 10,
              maxWidth: "200px",
              width: "100%",
            }}
          >
            <GooglePlayButton
              url={APKUrl}
              theme={"dark"}
              width={"100%"}
              height={"auto"}
              className={"custom-style"}
            />

            <AppStoreButton
              url={IOSUrl}
              theme={"dark"}
              width={"100%"}
              height={"auto"}
              className={"custom-style"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileAppsButtons;
