import React, { useContext, useRef, createContext } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { GrChat } from "react-icons/gr";
import Logo from "../assets/Logo/Logo_hor_green.svg";

import NavLinksContainer from "./NavLinksContainer";
export const scrollContext = createContext(null);

const isToday = (date) => dayjs(date).isSame(dayjs(), "day");
const isYesterday = (date) =>
  dayjs(date).isSame(dayjs().subtract(1, "day"), "day");
const isWithinLast7Days = (date) =>
  dayjs(date).isAfter(dayjs().subtract(7, "days"));
const isWithinLast30Days = (date) =>
  dayjs(date).isAfter(dayjs().subtract(30, "days"));

const categorizeChatLog = ({ urls }) => {
  if (!Array.isArray(urls)) {
    return {
      today: [],
      yesterday: [],
      last7Days: [],
      last30Days: [],
      older: [],
    };
  }

  const today = [];
  const yesterday = [];
  const last7Days = [];
  const last30Days = [];
  const older = [];

  urls.forEach((data) => {
    const createdAt = data.createdAt;

    if (isToday(createdAt)) {
      today.push(data);
    } else if (isYesterday(createdAt)) {
      yesterday.push(data);
    } else if (isWithinLast7Days(createdAt)) {
      last7Days.push(data);
    } else if (isWithinLast30Days(createdAt)) {
      last30Days.push(data);
    } else {
      older.push(data);
    }
  });

  return { today, yesterday, last7Days, last30Days, older };
};

const HistorySideNav = ({ urls, scrollTo }) => {
  const refs = useRef([]);

  const categorizedChatLog = categorizeChatLog({ urls });
  const navigate = useNavigate();

  const handleGoToChatClick = () => {
    navigate("/dashboard");
  };
  // const shouldBeScrolled = () => {
  //   navigate("/dashboard");
  // };

  return (
    <>
      <aside className="sideMenu">
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            className="navItems"
            style={{
              filter: "none",
              cursor: "pointer",
              position: "relative",
              transition: "filter 0.3s",
              top: "0",
            }}
          >
            <div className="d-flex flex-row justify-content-center">
              <img className="side-logo" src={Logo} alt="" />
            </div>
            <div
              className="d-flex flex-row justify-content-center"
              style={{ marginBottom: "15px" }}
            >
              <button
                onClick={handleGoToChatClick}
                style={{
                  backgroundColor: "#bbff4b",
                  color: "#000",
                  border: "none",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                <IoMdArrowRoundBack />
                Go back to your Chat
              </button>
            </div>
            <div className="navPromptWrapper">
              {categorizedChatLog.today.length > 0 && (
                <>
                  <h6>Today</h6>
                  {categorizedChatLog.today.map((url, idx) => (
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "#353540",
                        borderRadius: "15px",
                      }}
                      key={idx}
                      ref={(el) => (refs.current[idx] = el)}
                      onClick={() => {
                        scrollTo(idx);
                      }}
                    >
                      <div
                        className="navPrompt"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "80%" }}
                        >
                          <GrChat
                            style={{
                              color: "inherit",
                              height: "1.5rem",
                              width: "1.5rem",
                            }}
                          />
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              cursor: "pointer",
                              padding: "5px",
                              borderRadius: "0.5rem",
                              color: "inherit",
                            }}
                          >
                            <span
                              style={{
                                margin: "0rem auto 0rem 5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "1rem",
                                marginRight: "auto",
                              }}
                            >
                              {url.originalUrl}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {categorizedChatLog.yesterday.length > 0 && (
                <div>
                  <h6>Last 7 Days</h6>
                  {categorizedChatLog.yesterday.map((url, idx) => (
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "#353540",
                        borderRadius: "15px",
                      }}
                      key={idx}
                      ref={(el) => (refs.current[idx] = el)}
                      onClick={() => {
                        scrollTo(idx);
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "80%" }}
                      >
                        <GrChat
                          style={{
                            color: "inherit",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            padding: "5px",
                            borderRadius: "0.5rem",
                            color: "inherit",
                          }}
                        >
                          <span
                            style={{
                              margin: "0rem auto 0rem 5px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              marginLeft: "1rem",
                              marginRight: "auto",
                            }}
                          >
                            {url.originalUrl}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {categorizedChatLog.last7Days.length > 0 && (
                <div>
                  <h6>Last 7 Days</h6>
                  {categorizedChatLog.last7Days.map((url, idx) => (
                    <div
                      key={`d${url._id}`}
                      style={{
                        color: "white",
                        backgroundColor: "#353540",
                        borderRadius: "15px",
                      }}
                      ref={(el) => (refs.current[idx] = el)}
                      onClick={() => {
                        scrollTo(idx);
                      }}
                      name={url._id}
                    >
                      <div
                        className="navPrompt"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "80%" }}
                        >
                          <GrChat
                            style={{
                              color: "inherit",
                              height: "1.5rem",
                              width: "1.5rem",
                            }}
                          />
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              cursor: "pointer",
                              padding: "5px",
                              borderRadius: "0.5rem",
                              color: "inherit",
                            }}
                          >
                            <span
                              style={{
                                margin: "0rem auto 0rem 5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "1rem",
                                marginRight: "auto",
                              }}
                            >
                              {url.originalUrl}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {categorizedChatLog.last30Days.length > 0 && (
                <div>
                  <h6>Last 30 Days</h6>

                  {categorizedChatLog.last30Days.map((url, idx) => (
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "#353540",
                        borderRadius: "15px",
                      }}
                      key={idx}
                      ref={(el) => (refs.current[idx] = el)}
                      onClick={() => {
                        scrollTo(idx);
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "80%" }}
                      >
                        <GrChat
                          style={{
                            color: "inherit",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            padding: "5px",
                            borderRadius: "0.5rem",
                            color: "inherit",
                          }}
                        >
                          <span
                            style={{
                              margin: "0rem auto 0rem 5px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              marginLeft: "1rem",
                              marginRight: "auto",
                            }}
                          >
                            {url.originalUrl}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {categorizedChatLog.older.length > 0 && (
                <>
                  <h6>Older</h6>
                  {categorizedChatLog.older.map((url, idx) => (
                    <div
                      style={{
                        marginTop: "0.7rem",
                      }}
                      className="navPrompt"
                      key={idx}
                    >
                      <GrChat
                        style={{
                          color: "#fff",
                          height: "1.5rem",
                          width: "2rem",
                        }}
                      />
                      <p>{url.originalUrl}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <NavLinksContainer />
        </div>
      </aside>
    </>
  );
};

export default HistorySideNav;
