import React, { useContext, useEffect, useRef, useState } from "react";
import { GrChat } from "react-icons/gr";
import { API_BASE_URL } from "../api.config";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ShareWithTeamModal from "./ShareWithTeamModal";
import axios from "axios";
import { Context } from "../Context/ContexApi";
import styles from "./nav.module.css";

const NavPrompt = ({
  setSelectedTab,
  originalUrl,
  urlId,
  setShowMenu,
  sharedWith,
  filteredMembers,
}) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [members, setMembers] = useState([]);
  const [invisibleToAdmin, setInvisibleToAdmin] = useState(false);
  const [shareStatus, setShareStatus] = useState(sharedWith ?? []);
  // const [selectedTab, setSelectedTab] = useState(false);

  const dropdownRef = useRef(null);
  // @ts-ignore

  const { setChatLog } = useContext(Context);

  //localstorage
  const userId = localStorage.getItem("user_id");
  const organizationId = localStorage.getItem("organization_id");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const plan = localStorage.getItem("plan");

  // console.log('role', role)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleItemClick = (item) => {
    if (item === "Share with Team members") {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleClickOutside = (event) => {
    // @ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleNavPromptClick = async () => {
    navigate("/dashboard");
    setSelectedTab(false);

    try {
      if (userId && token) {
        const response = await axios.post(
          `${API_BASE_URL}/check-url`,
          {
            url: originalUrl,
            userId,
            organizationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              organizationId: localStorage.getItem("organization_id"),
            },
          }
        );
        if (response.status === 201) {
          const responseMessage = response.data.Link.responseMessage;
          // console.log(data)
          setChatLog((prevChatLog) => [
            ...prevChatLog,
            { chatPrompt: originalUrl, botMessage: responseMessage },
          ]);
          setSelectedTab(response.status === 201);
        } else {

          console.error("Failed to fetch URL data");
        }
      } else {
        
        console.error("User ID or token not found in localStorage");
      }
    } catch (error) {
      console.log(error)
      setChatLog((prevChatLog) => [
        ...prevChatLog,
        { chatPrompt: originalUrl, botMessage: error.response.data.error },
      ]);
      console.error("An error occurred while fetching URL data:", error);
    }

    setShowMenu(false);
  };

  const handleToggleInvisibleToAdmin = async (newState) => {
    try {
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      const response = await axios.post(
        `${API_BASE_URL}/team/update-invisible-to-admin`,
        {
          urlId: urlId,
          invisibleToAdmin: newState,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            organizationId: localStorage.getItem("organization_id"),
          },
        }
      );

      // console.log('Toggle Invisible to Admin Response:', response.data)
      setInvisibleToAdmin(newState);
    } catch (error) {
      console.error("Error toggling Invisible to Admin:", error);
    }
  };

  const handleShareToggle = async (memberId) => {
    try {
      const newShareStatus = !shareStatus.includes(memberId);
      const response = await axios.post(
        `${API_BASE_URL}/team/share-single-url`,
        {
          urlId: urlId,
          memberId,
          userId,
          share: newShareStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { organizationId: localStorage.getItem("organization_id") },
        }
      );

      // console.log('Share Toggle Response:', response.data)

      setShareStatus((prev) =>
        prev.includes(memberId)
          ? prev.filter((id) => id !== memberId)
          : [...prev, memberId]
      );
    } catch (error) {
      console.error("Error toggling share status:", error);
    }
  };

  const truncateText = (text, charLimit) => {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + "...";
    }
    return text;
  };

  return (
    <div
      className={`navPrompt`}
      style={{ position: "relative", color: "inherit" }}
    >
      {/* <div className= {`${styles.line}`}></div> */}
      <div className="d-flex align-items-center" style={{width: "80%"}}>
        <GrChat
          style={{
            color: "inherit",
            height: "1.5rem",
            width: "1.5rem",
          }}
        />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            padding: "5px",
            borderRadius: "0.5rem",
            color: "inherit",
          }}
          onClick={handleNavPromptClick}
        >
          <span
            style={{
              margin: "0rem auto 0rem 5px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              marginLeft: "1rem",
              marginRight: "auto",
            }}
          >
            {originalUrl}
            {/* {truncateText(originalUrl, 10)}{" "} */}
          </span>
        </p>
      </div>

      {plan === "team" && (
        <div style={{ position: "relative" }}>
          <button
            onClick={toggleMenu}
            className="menu-button"
            style={{ color: "inherit" }}
          >
            &#8942;
          </button>
          {isMenuOpen && (
            <div
              ref={dropdownRef}
              className="custom-dropdown"
              role="menu"
              aria-orientation="vertical"
              data-state="open"
              style={{ outline: "none" }}
            >
              {role === "member" && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.75rem 1rem",
                    cursor: "pointer",
                  }}
                  onClick={handleToggleInvisibleToAdmin}
                >
                  <div
                    role="menuitem"
                    className="dropdown-item"
                    style={{ flexGrow: 1, marginRight: "0.5rem" }}
                  >
                    Invisible to admin
                  </div>
                  <Form.Check
                    type="switch"
                    label=""
                    checked={invisibleToAdmin}
                    onChange={() =>
                      handleToggleInvisibleToAdmin(!invisibleToAdmin)
                    }
                  />
                </div>
              )}
              <div
                role="menuitem"
                className="dropdown-item"
                onClick={() => handleItemClick("Share with Team members")}
              >
                Share Chat
              </div>
            </div>
          )}
        </div>
      )}

      <ShareWithTeamModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        filteredMembers={filteredMembers}
        toggleShare={handleShareToggle}
        originalUrl={originalUrl}
        shareStatus={shareStatus}
      />
    </div>
  );
};

export default NavPrompt;
